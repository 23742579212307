import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Ribbon from './Ribbon'
import Dotted from '../assets/BlackDotted.svg'
import { pressHotkeys } from '../helpers/utils'

const Wrapper = styled.a`
  height: 200px;
  width: 168px;
  border-radius: 16px;

  background-color: #fff;

  position: relative;

  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);

  :before {
    content: ' ';
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Dotted});
    opacity: 0.5;
    position: absolute;
    background-position: 50% 0;
    background-position: center;
    background-repeat: no-repeat;
  }

  margin: 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.6')};
  text-decoration: none;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 168px;
  max-width: 126px;
  color: var(--mainTextColor);
  padding: 5px 10px;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${({ theme }) =>
      theme.retailer.default ? theme.retailer.primaryColor : '#221f20'};
  }
  .body {
    font-size: 14px;
    color: ${({ theme }) =>
      theme.retailer.default ? theme.retailer.primaryColor : '#221f20'};

    line-height: 18px;
  }

  hr {
    width: 90%;
    border-color: #f9f9f91c;
  }
`

const LinkBadge = ({ title, body, url, isNew, handleClick, isActive }) => (
  <Wrapper 
    isActive={isActive} 
    tabIndex="0"
    aria-label={`${title} ${body} opens in new window.`}
    onClick={handleClick}
    onKeyDown={pressHotkeys}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
      <Card>
        {isNew && <Ribbon />}
        <div className="title" aria-hidden="true">
          {title}
        </div>
        <hr />
        <div className="body" aria-hidden="true">
          {body}
        </div>
      </Card>
  </Wrapper>
)

LinkBadge.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  url: PropTypes.string,
  isNew: PropTypes.bool,
}

export default LinkBadge
