import React, { useState, useContext, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { COLOR } from '../constants/color'
import HamburgerMenu from './HamburgerMenu'
import MetadataManager from './MetadataManager'
import BlackBurger from '../assets/BlackBurger.svg'
import Man from '../assets/Man.svg'
import Woman from '../assets/Woman.svg'
import { UserContext } from '../UserContext'
import { useConfigContext, useLocalStorageState } from '../hooks'
import theme from '../constants/theme'
import BlueTracFone from '../assets/BlueLogo.svg'
import { getParam } from '../helpers/parameters'
import ErrorBoundary from './ErrorBoundary'

const Wrapper = styled.div`
  display: flex;
  background: ${({ isLightTheme, theme }) =>
    isLightTheme ? '#fff' : theme.retailer.header};
  height: 110px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 300px) and (max-width: 767px) {
    height: 80px;
  }
`

const LogoWrapper = styled.div`
  padding: 26px 0 26px 152px;

  img {
    width: 83px;
    height: 58px;
  }

  @media (min-width: 300px) and (max-width: 767px) {
    padding-left: 40px;
  }
`

const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const HamburgerWrapper = styled.div`
  padding: 23px 158px 20px 0px;

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }

  img {
    filter: ${({ theme }) =>
      theme.retailer.lightTheme ? 'invert(1)' : 'inherit'};
  }

  @media (min-width: 300px) and (max-width: 767px) {
    padding-right: 10px;
  }
`

const UserWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  img {
    border: 1px solid #fff;
    border-radius: 50px;
    height: 22px;
    width: 22px;

    margin-right: 10px;

    background-color: #fff;
  }

  span {
    color: ${({ theme }) => (theme.retailer.lightTheme ? '#fff' : '#221f20')};
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    text-align: initial;
  }

  p {
    color: ${COLOR.green};
    font-style: italic;
    margin: 0;
    font-size: 14px;
  }
`

const SkipLink = styled.a`
  margin-right: 1rem;
  padding: 20px 50px;
  transform: translateX(-200%);
  position: absolute;
  transition: transform 0.3s;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.retailer.primaryColor};

  color: #fff;

  font-size: 24px;

  :focus {
    transform: translateX(0);
  }
`

const Header = ({
  wrpStyle,
  inverted,
  children,
  notShow,
  isLightTheme,
  pageName = '',
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { userName, userGender } = useContext(UserContext)
  const [showOnboarding] = useLocalStorageState('showOnboarding', true)
  const menuRef = useRef(null)
  const [justClosedMenu, setMenuClose] = useState(false)
  const { customerConfig } = useConfigContext()
  const iframeHost = getParam('iframeHost')

  const history = useHistory()

  const showHamburger = !!userName && !!userGender && !showOnboarding

  const linkItems = [
    {
      title: 'Skip to Main Content',
      link: '#main-content',
    },
    {
      title: 'Skip to Main Menu',
      link: '/main-menu',
    },
    {
      title: 'Skip to Explore More',
      link: '/explore-more',
    },
    {
      title: 'Skip to My Badges',
      link: '/badges',
    },
    {
      title: 'Skip to Learning Curve',
      link: '/learning-curve',
    },
  ]

  useEffect(() => {
    // Add button focus to open hamburger menu only when just closed
    if (!menuOpen && justClosedMenu) menuRef.current.focus()
    setMenuClose(false)
  }, [menuOpen, justClosedMenu])

  return (
    <>
      <Wrapper isLightTheme={isLightTheme} style={wrpStyle} className="header">
        <iframe src={`${iframeHost}/`}width='1' height='1' style={{ position: 'absolute', visibility: 'hidden' }} />
        <MetadataManager title={pageName} />
        {!notShow && linkItems
          .filter((item) => item.link !== history.location.pathname)
          .map((item, index) => (
            <SkipLink
              className={`link-item_${index + 1}`}
              key={index}
              aria-label={item.title}
              href={item.link}
            >
              {item.title}
            </SkipLink>
          ))}
        <a
          tabIndex="0"
          href={customerConfig.retailerLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`${customerConfig.customerModalName} Logo opens in new window.`}
        >
          <LogoWrapper>
            <img
              src={isLightTheme ? BlueTracFone : theme.retailer.icon.src}
              width={120}
              height={42}
              alt={''}
            />
          </LogoWrapper>
        </a>
        <UserContainer>
          {notShow
            ? null
            : userGender &&
              inverted && (
                <UserWrapper>
                  <img aria-hidden src={userGender === 'male' ? Man : Woman} alt="" />
                  <span aria-label={`${userName} profile`}>
                    {userName}
                  </span>
                </UserWrapper>
              )}
          <HamburgerWrapper>
            <button
              tabIndex="0"
              ref={menuRef}
              style={showHamburger ? {} : { visibility: 'hidden' }}
              className="burger"
              data-intro-anchor="burger"
              aria-label="Open Menu. Colapsed"
              onClick={() => setMenuOpen(true)}
            >
              <img aria-hidden src={BlackBurger} width={38} height={19} alt="" />
            </button>
          </HamburgerWrapper>
        </UserContainer>
        {menuOpen && (
          <HamburgerMenu
            showModal={menuOpen}
            close={() => {
              setMenuOpen(false)
              setMenuClose(true)
            }}
          />
        )}
        {children}
      </Wrapper>
    </>
  )
}

const WithErrorBoundaryHeader = (props) => (
  <ErrorBoundary>
    <Header {...props} />
  </ErrorBoundary>
)

export default WithErrorBoundaryHeader
