import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import BackgroundCircle from '../assets/BackgroundCircle.svg'

const BackgroundRing = styled.div`
  background: url(${BackgroundCircle});
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  height: 565px;
  width: 522px;

  position: relative;

  @media (min-width: 340px) and (max-width: 760px) {
    background-size: 370px;

    height: 400px;
    width: 368px;
  }
`

const BackgroundLoader = styled.div`
  border: 27px solid;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  border-color: transparent;

  background-color: ${({ theme }) =>
    theme.retailer.default ? theme.retailer.primaryColor : '#221f20'};

  width: 242px;
  height: 242px;

  animation-name: rotate;
  animation-iteration-count: 1;
  animation-duration: 3.5s;

  animation-fill-mode: forwards;

  transform: rotate(45deg);

  @media (min-width: 340px) and (max-width: 760px) {
    width: 144px;
    height: 144px;
  }

  ${({ theme }) => css`
    @keyframes rotate {
      0% {
        border-top-color: transparent;
      }

      25% {
        border-top-color: ${theme.retailer.default
          ? '#57DF57 '
          : theme.retailer.primaryColor};
      }

      50% {
        border-right-color: ${theme.retailer.default
          ? '#57DF57 '
          : theme.retailer.primaryColor};
      }

      75% {
        border-bottom-color: ${theme.retailer.default
          ? '#57DF57 '
          : theme.retailer.primaryColor};
      }

      100% {
        border-color: ${theme.retailer.default
          ? '#57DF57 '
          : theme.retailer.primaryColor};
      }
    }
  `}
`

const Border = styled.div`
  border: ${({ theme }) =>
    `4px solid ${
      theme.retailer.default ? theme.retailer.primaryColor : '#221f20'
    }}`};
  border-radius: 50%;
  width: 249px;
  height: 249px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  box-shadow: 0px -2px 62px 5px #c7e64e;

  @media (min-width: 340px) and (max-width: 760px) {
    width: 153px;
    height: 153px;
  }
`

const Wrapper = styled.div`
  width: 253px;
  height: 246px;
  position: relative;
  vertical-align: middle;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position-x: center;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 340px) and (max-width: 760px) {
    width: 192px;
    height: 154px;
  }
`

const Percents = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  z-index: 1;
`

const Percent = styled.span`
  font-size: 48px;
  font-weight: 700;
`

const Text = styled(Percent)`
  font-size: 24px;

  @media (min-width: 340px) and (max-width: 760px) {
    font-size: 20px;
  }
`

const ANIMATION_DELAY = 100

const Loader = ({ onComplete }) => {
  const [percent, setPercent] = useState('0%')
  const timeoutsRef = useRef([])

  const rotateIcons = (percents, count) => {
    if (count >= 4) {
      setPercent('100%')
      onComplete && onComplete()
      return
    }
    setPercent(percents[count])

    const timeoutId = setTimeout(() => {
      const c = count + 1
      rotateIcons(percents, c)
    }, ANIMATION_DELAY)

    timeoutsRef.current.push(timeoutId)
  }

  useEffect(() => {
    const percents = ['0%', '25%', '50%', '75%', '100%']
    rotateIcons(percents, 0)

    return () => {
      timeoutsRef.current.forEach(clearTimeout)
      timeoutsRef.current = []
    }
  }, [])

  return (
    <BackgroundRing>
      <Border>
        <Percents>
          <Percent>{percent}</Percent>
          <Text>Completed</Text>
        </Percents>
        <Wrapper>
          <BackgroundLoader />
        </Wrapper>
      </Border>
    </BackgroundRing>
  )
}

export default Loader
