import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
import FocusTrap from 'focus-trap-react'
import ResetProgress from './ResetProgress'
import { useConfigContext } from '../hooks'
import { pressHotkeys } from '../helpers/utils'

const Wrapper = styled.div`
  z-index: 1002;
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  a {
    font-size: 20px;
    color: #313334;
    text-align: right;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    text-align: start;
  }

  .close {
    color: #1a3e86;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    background: none;
    display: flex;
    align-items: center;
    padding-left: 30px;

    &:before {
      content: '';
      height: 4px;
      width: 4px;
      display: inline-block;
      border-top: #012979 solid 2px;
      border-left: #012979 solid 2px;
      transform: rotate(317deg);
    }
  }
`

const LeftSide = styled.div`
  background: ${({ theme }) => theme.retailer.primaryColor};
  width: 70px;
  height: 100%;
`

const RightSide = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LinkWrapper = styled.ul`
  display: flex;
  flex-flow: column;
  padding-top: 30px;
  list-style: none;
  padding: 0;

  ${(props) =>
    props.theme.media.iphone7(css`
      padding-top: 0;
    `)}

  li {
    z-index: 10;
  }

  span {
    padding: 20px 0px 15px 30px;
    display: block;

    @media (max-width: 900px) {
      padding: 5px 0px 10px 30px;
    }
  }
`

const GreyLine = styled.hr`
  width: 88%;
  height: 1px;
  background: #dbe0ec;
  border: none;
  margin: 0px auto;
`

const SeparationLine = styled.hr`
  width: 65px;
  height: 2px;
  background: #aeafb0;
  border: none;
  margin: 0 0px 0px 25px;
`

const BurgerMenu = ({ close, showModal }) => {
  const isTestingEnv =
    JSON.parse(localStorage.getItem('device')).displayName ===
    'Testing environment'
  const { customerConfig } = useConfigContext()

  return (
    <FocusTrap active={showModal}>
      <Wrapper
        className="animate__animated animate__slideInRight"
        aria-modal="true"
      >
        <LeftSide />
        <RightSide>
          <button onClick={close} className="close" autoFocus>
            Close
          </button>
          <LinkWrapper>
            <li>
              <Link to="/main-menu" replace onClick={close} onKeyDown={pressHotkeys}>
                <span>Main menu</span>
              </Link>
            </li>
            <GreyLine />
            <li>
              <NavLink to="/explore-more" onClick={close} onKeyDown={pressHotkeys}>
                <span>Explore more</span>
              </NavLink>
            </li>
            <GreyLine />
            <li>
              <NavLink to="/badges" onClick={close} onKeyDown={pressHotkeys}>
                <span>Badges</span>
              </NavLink>
            </li>
            <GreyLine />
            <li>
              <NavLink to="/learning-curve" onClick={close} onKeyDown={pressHotkeys}>
                <span>My Learning Curve</span>
              </NavLink>
            </li>
          </LinkWrapper>
          <SeparationLine />
          <LinkWrapper>
            {customerConfig.deLink && (
              <li>
                <a
                  onKeyDown={pressHotkeys}
                  href={customerConfig.deLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span>Decision Engine</span>
                </a>
              </li>
            )}
            <GreyLine />
            <li>
              <a
                onKeyDown={pressHotkeys}
                href={customerConfig.faqLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Faq</span>
              </a>
            </li>
            <GreyLine />
            <li>
              <a
                onKeyDown={pressHotkeys}
                href={customerConfig.contactUsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Contact us</span>
              </a>
            </li>
            <GreyLine />
            <li>
              <NavLink to="/chatty-introduction" onKeyDown={pressHotkeys}>
                <span>{customerConfig.botName}</span>
              </NavLink>
            </li>
            <GreyLine />
            <li>
              <a
                onKeyDown={pressHotkeys}
                href={customerConfig.myAccountLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Go to my account</span>
              </a>
            </li>
          </LinkWrapper>
          {isTestingEnv && <ResetProgress />}
        </RightSide>
        <p
          role="alert"
          tabIndex={-1}
          style={{ opacity: '0', position: 'absolute' }}
        >
          {showModal && 'Main Menu. Expanded'}
        </p>
      </Wrapper>
    </FocusTrap>
  )
}

export default BurgerMenu
