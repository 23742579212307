export const loadScript = (url, callback) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  if (script.readyState) { // IE
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback && callback()
      }
    }
  } else { // Others
    script.onload = function () {
      callback && callback()
    }
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}
