import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import LinkBadge from '../components/LinkBadge'
import { BadgesCtx } from '../BadgesCtx'
import BadgeAward from '../components/BadgeAward'
import BreadCrumbs from '../components/BreadCrumbs'
import { useConfigContext, useLocalStorageState } from '../hooks'
import PageSwitcher from '../components/PageSwitcher'
import { hardcodedDataOnPage } from '../constants/assets/data'

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  margin: 0 auto;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Title = styled.h1`
  color: #313334;
  font-size: 22px;
  text-transform: uppercase;
  margin: 6px;
  margin-top: 55px;

  @media (min-width: 360px) and (max-width: 767px) {
    margin-top: 25px;
  }
`

const Info = styled.p`
  color: #5d5d5d;
  font-size: 14px;
  padding-top: 15px;
  width: 90%;
  margin: 0 auto;
`

const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Award = ({ badge }) => {
  const [isAwardBadgeShown, setIsAwardBadgeShown] = useLocalStorageState(
    `badge-award-${badge.id}`,
    false
  )

  if (!badge.isDone || isAwardBadgeShown) {
    return null
  }

  return (
    <BadgeAward
      name={badge.name}
      onDismiss={() => setIsAwardBadgeShown(true)}
      badgeId={badge.id}
      fileId={badge.fileId}
    />
  )
}

const Discover = () => {
  const [extraBadgesProgress, setExtraBadgesProgress] =
    useLocalStorageState('extraBadges')
    const { completeExtraBadge, completeExtraBadgeTask } = useContext(BadgesCtx)
  const { customerConfig: { customer } } = useConfigContext()
  const badge = extraBadgesProgress.find((bdg) => bdg.name === 'Exploring')
  const badgeId = badge.id
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customer].pagesData.discover,
    []
  )
  const [isAwardBadgeShown, setIsAwardBadgeShown] = useLocalStorageState(
    `badge-award-${badge.id}`,
    false
  )
  const tiles = pagesData.links

  const handleClick = (title) => () => {
    completeExtraBadgeTask({ badgeId, taskTitle: title })
    const isAllTasksDone = badge.tasks.filter((task) => !task.isDone).length === 1
    if (isAllTasksDone) {
      completeExtraBadge(badgeId)
    }
  }

  const getTask = (title) => {
    const tasks = badge.tasks || []
    return tasks.find((task) => task.title === title)
  }

  const linkBadges = tiles.map((tile, index) => {
    const task = getTask(tile.title)
    return (
      <LinkBadge
        {...tile}
        key={index}
        handleClick={handleClick(task.title)}
        isActive={!task?.isDone}
      />
    )
  })

  const historyPages = [
    { title: 'Main Menu', link: '/main-menu' },
    {
      title: pagesData.title
    },
  ]

  return (
    <>
      {(badge && badge.isDone && !isAwardBadgeShown) ? (
        <Award badge={badge} />
      ) : (
        <Wrapper>
          <Header inverted={true} pageName={'Discover'} />
          <PageSwitcher
            navLeft='/main-menu'
            navRight='/badges'
            pageNumber={2}
          />
          <BreadCrumbs historyPages={historyPages} />
          <Title>{pagesData.title}</Title>
          <Info>{pagesData.description}</Info>
          <Tiles>{linkBadges}</Tiles>
        </Wrapper>
      )}
    </>
  )
}

export default Discover
