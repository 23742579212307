import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { postAnalytics } from '../services/analytics'
import useConfigContext from './useConfigContext'
import { getParam } from '../helpers/parameters'

const usePageTracking = () => {
  const location = useLocation()
  const { customerConfig } = useConfigContext()
  const [initialized, setInitialized] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const iframeHost = getParam('iframeHost')

  const listenMessageIframe = (e) => {
    if (e.origin !== iframeHost || typeof e.data !== 'string') return
    const iframeData = JSON.parse(e.data)
    const permanentId = localStorage.getItem('permanent_id')
    if (iframeData.permanent_id && permanentId !== iframeData.permanent_id) {
      localStorage.setItem('permanent_id', iframeData.permanent_id)
    }
    const [, pagePath] = location.pathname.split('/')
    postAnalytics({
      category: 'pageviews',
      data: {
        page_title: pagePath || 'homepage',
        customer: customerConfig.customer,
      },
    })
    setIsFirstRender(false)
  }

  useEffect(() => {
    window.addEventListener('message', listenMessageIframe)
    ReactGA.initialize(customerConfig.gtmId)
    setInitialized(true)
    return () => window.removeEventListener('message', listenMessageIframe)
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)

      const [, pagePath] = location.pathname.split('/')
      setIsFirstRender(false)
      if (isFirstRender) return
      postAnalytics({
        category: 'pageviews',
        data: {
          page_title: pagePath || 'homepage',
          customer: customerConfig.customer,
        },
      })
    }
  }, [initialized, location])
}

export default usePageTracking
