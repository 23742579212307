import React from 'react'
import styled from 'styled-components'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;

  p {
    padding: 20px 10px;
    border: 1px solid #e3e3e3;
    border-radius: 7px;
  }
`

const ErrorBoundaryContent = () => (
  <Container>
    <p>Oops! Something went wrong</p>
  </Container>
)

const ErrorBoundary = ({ children }) => (
  <SentryErrorBoundary fallback={<ErrorBoundaryContent />}>
    {children}
  </SentryErrorBoundary>
)

export default ErrorBoundary
