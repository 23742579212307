import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import NavButton from '../components/NavButton'
import Header from '../components/Header'
import PageSwitcher from '../components/PageSwitcher'
import DiscoverPath from '../assets/DiscoverPath.svg'
import { useConfigContext } from '../hooks'
import { hardcodedDataOnPage } from '../constants/assets/data'

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  margin: 0 auto;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Info = styled.p`
  color: #373737;
  font-size: 14px;
  width: 90%;
  margin-top: 0px;

  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #313334;
    font-weight: 700;
    font-size: 22px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h2 {
    font-weight: 200;
    color: #373737;
    font-size: 14px;
    margin: 0 auto 56px auto;
  }

  @media (min-width: 360px) and (max-width: 767px) {
    padding-top: 50px;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const PathWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Patch = styled.div`
  background: url(${DiscoverPath}) no-repeat;
  width: 70px;
  margin-left: 20px;
`

const TextWrapper = styled.div`
  text-align: start;
  margin-bottom: 45px;

  h2 {
    color: #313334;

    text-transform: uppercase;
    font-size: 18px;
    margin: 0px;
    padding-bottom: 10px;
  }

  p {
    color: #373737;
    font-size: 14px;
    margin: 0px;
    padding-bottom: 10px;
    line-height: 14px;
  }
`

const Explore = () => {
  const history = useHistory()
  const { customerConfig} = useConfigContext()
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.explore,
    []
  )

  return (
    <Wrapper>
      <Header pageName={'Explore More'} inverted={true} />
      <PageSwitcher
        navLeft="/main-menu"
        navRight="/badges"
        pageTitle="Explore more"
        pageNumber={2}
      />
      <main>
        <Info>
          <h1>{pagesData.title}</h1>
          <h2>{`${pagesData.description} ${customerConfig.customerModalName}.`}</h2>
        </Info>
        <PathWrapper id="main-content">
          <Patch />
          <Body>
            {pagesData.steps.map((i, index) => (
              <TextWrapper key={`${i.title}-${index}`}>
                <h2>{i.title}</h2>
                <p>{i.description}</p>
              </TextWrapper>
            ))}
          </Body>
        </PathWrapper>
        <NavButton
          href='/discover'
          title={`Let's discover ${customerConfig.customerModalName}`}
        />
      </main>
    </Wrapper>
  )
}

export default Explore
