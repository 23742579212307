import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../pages/Home'
import MainMenu from '../pages/MainMenu/index'
import Loading from '../pages/Loading'
import Welcome from '../pages/Welcome'
import Registration from '../pages/Registration'
import ChattyIntroduction from '../pages/ChattyIntroduction'
import Badges from '../pages/Badges'
import Explore from '../pages/Explore'
import TutorialComplete from '../pages/TutorialComplete'
import Discover from '../pages/Discover'
import Protect from '../pages/Protect'
import { BadgeTasks, TaskSteps } from '../pages/BadgeTasks'
import LearningCurve from '../pages/LearningCurve'
import { withSentryRouting } from '@sentry/react'

const SentryRoute = withSentryRouting(Route)

const createRoutes = () => (
  <Switch>
    <SentryRoute path="/registration" component={Registration} />
    <SentryRoute path="/chatty-introduction" component={ChattyIntroduction} />
    <SentryRoute path='/loading' component={Loading} />
    <SentryRoute path='/welcome' component={Welcome} />
    <SentryRoute path="/main-menu" component={MainMenu} />
    <SentryRoute path="/explore-more" component={Explore} />
    <SentryRoute path="/learning-curve" component={LearningCurve} />
    <SentryRoute path="/badges/:badgeId" component={BadgeTasks} />
    <SentryRoute path="/badges" component={Badges} />
    <SentryRoute path="/tutorials/:id" component={TaskSteps} />
    <SentryRoute path="/tutorial-complete" component={TutorialComplete} />
    <SentryRoute path="/discover" component={Discover} />
    <SentryRoute path="/protect" component={Protect} />
    <SentryRoute exact path="/" component={Home} />
  </Switch>
)

export default createRoutes
