export const useControlBot = () => {
  const sendBotpressPendingMessage = () => {
    if (typeof window === 'undefined') return
    const sessionId = sessionStorage.getItem('sessionId')
    let message = {
      webSessionId: sessionId || '',
    }

    window.botpressPendingMessage = message
  }

  return { sendBotpressPendingMessage }
}
