import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import NavButton from '../components/NavButton'
import Header from '../components/Header'
import { COLOR } from '../constants/color'
import ChattyWay from '../assets/ChattyWay.svg'
import { useConfigContext } from '../hooks'
import { hardcodedDataOnPage } from '../constants/assets/data'
import useImage from '../hooks/useImage'

const Wrapper = styled.div`
  height: 100%;
`

const TopBlock = styled.div`
  margin-top: -16px;
  height: 52px;
`

const Gradient = styled.div`
  text-align: center;

  h1 {
    font-size: 22px;
    color: ${COLOR.black};
    margin: 0px;
  }

  h4 {
    text-transform: uppercase;
    color: ${COLOR.orange};
    margin: 0px;
  }

  p {
    color: black;
    font-size: 16px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 340px;
  max-width: 390px;
  margin: 0 auto;
  margin-top: 130px;
  font-weight: 100;
  opacity: 0.8;

  h1 {
    font-size: 22px;
    margin-bottom: 30px;
    color: black;
  }

  p {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 400px) {
    margin-top: 40px;
  }
`

const Icon = styled.img`
  margin-top: 0%;
  animation: fadeOut ease 2s;
  margin-top: 20px;
  box-shadow: none !important;
`

const WayWrapper = styled.div`
  box-shadow: none !important;
`

const WayText = styled.p`
  display: flex;
  flex-flow: column;
  min-width: 340px;
  max-width: 390px;
  margin: 0 auto;
  margin-top: 35px;
  font-size: 20px;
  color: #221f20;
`

const WrapperChatty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
  margin-bottom: -200px;
  @media (min-width: 1900px) {
    margin-right: 420px;
  }
  @media (min-width: 1600px) and (max-width: 1900px) {
    margin-right: 300px;
  }
  @media (min-width: 1400px) and (max-width: 1599px) {
    margin-right: 200px;
  }
  @media (min-width: 1245px) and (max-width: 1399px) {
    margin-right: 120px;
  }
  @media (min-width: 1025px) and (max-width: 1244px) {
    margin-right: 15px;
  }
  @media (min-width: 1024px) {
    margin-top: -35px;
  }
`

const WayIcon = styled.img`
  height: auto;
  max-height: 231px;
  margin-right: 50px;
  margin-top: -22px;
`

const CircleWrapper = styled.div`
  border-radius: 50%;
  width: ${({ showIcon }) => (showIcon ? '250px' : '73px')};
  height: ${({ showIcon }) => (showIcon ? '250px' : '73px')};
  margin: ${({ showIcon }) => showIcon && '12px auto'};
`

const ChattyText = styled.span`
  color: ${COLOR.darkOrange};
  font: normal normal bold 14px/19px Josefin Sans;
  text-transform: uppercase;
`

const ChattyIntroduction = () => {
  const [showIcon, setShowIcon] = useState(true)
  const { customerConfig } = useConfigContext()
  const history = useHistory()
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.chattyIntroduction,
    []
  )
  const { image } = useImage(`${customerConfig.customer}_bot.png`)


  useEffect(() => {
    setTimeout(() => {
      setShowIcon(false)
    }, 2000)
  }, [])

  return (
    <>
      <Wrapper>
        <Header inverted={true} pageName={'Chatty Introduction'} />
        <TopBlock />
        <main>
          <Gradient>
            <TextWrapper
              style={{ display: 'flex', flexFlow: 'column' }}
            >
              <div className='animate__animated animate__slideInLeft'>
                <h1>{pagesData.title}</h1>
              </div>
              <div
                className='animate__animated animate__slideInRight'
                dangerouslySetInnerHTML={{ __html: pagesData.description }}
              />
            </TextWrapper>
          </Gradient>
          {showIcon ? (
            <CircleWrapper showIcon={showIcon}>
              <Icon
                tabIndex='-1'
                className='animate__animated animate__bounceIn animate__delay-1s'
                src={image}
                width='auto'
                height={201}
                alt=''
                aria-hidden
              />
            </CircleWrapper>
          ) : (
            <WayWrapper className='animate__animated animate__fadeIn animate__delay-1s'>
              <WayText>
                Here's where I'll be waiting for you in case you need my help.
              </WayText>
              <NavButton
                onClick={() => {
                  history.replace('/main-menu')
                }}
                onKeyDown={(e) =>
                  e.key === 'Enter' && history.replace('/main-menu')
                }
                title='Got it!'
              />
              <WrapperChatty aria-hidden='true' tabIndex={-1}>
                <WayIcon src={ChattyWay} aria-hidden='true' tabIndex={-1} />
                <CircleWrapper></CircleWrapper>
              </WrapperChatty>
            </WayWrapper>
          )}
        </main>
      </Wrapper>
    </>
  )
}

export default ChattyIntroduction
