import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Line = styled.hr`
  width: 380px;
  height: 1px;
  background: ${({ theme }) =>
    theme.retailer.default ? '#57DF57' : `${theme.retailer.primaryColor}`};
  border: none;
  margin-top: 10px;
`

const CurvedLine = ({ wrpStyle, inverded }) => (
  <Wrapper inverded={inverded} style={wrpStyle}>
    <Line aria-hidden />
  </Wrapper>
)

export default CurvedLine
