import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import BlueBadge from './BlueBadge'
import WhiteDotted from '../assets/WhiteDotted.svg'
import BlackDotted from '../assets/BlackDotted.svg'
import { pressHotkeys } from '../helpers/utils'
import useImage from '../hooks/useImage'
import { getParam } from '../helpers/parameters'

const Wrapper = styled.div`
  height: 220px;
  width: 168px;
  border-radius: 16px;

  cursor: pointer;
  outline: dashed;
  outline-offset: -10px;
  outline-color: #a3a3a3;
  outline-width: 2px;

  :focus-visible {
    outline-offset: 6px;
    outline: auto   ;
    outline-color: black;
  }
  padding: 10px;  

  margin: 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  align-items: center;

  background-color: ${({ disabled, theme }) => (disabled ? theme.color.grayMid : theme.retailer.primaryColor)};
`

const Icon = styled.img`
  width: auto;
  height: auto;
  max-width: 73px;
  max-height: 73px;
  padding: 5px;
`

const Title = styled.p`
  text-transform: uppercase;
  color: ${({ disabled, theme }) => {
    if (disabled || theme.retailer.default) return '#fff'
    return '#221F20'
  }};
  font-size: 14px;
  width: 70%;
  line-height: 18px;
  margin-bottom: 2px;
  margin-top: 7px;
  font-weight: 700;
`

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgb(0 0 0 / 50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  background: ${({ disabled, color, theme }) => {
    if (disabled) return theme.color.grayMid
    if (theme.retailer.default) return color
    if (theme.retailer.lightBadge) return '#ffffff'
    return '#221F20'
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  border-radius: 50%;
  border: ${({ disabled, theme }) => `3px solid ${
    disabled ? '#F9F9F9' : !theme.retailer.lightBadge && '#F9F9F9'
  }`};

  width: 120px;
  height: 120px;
`

const InnerContainer = styled(Container)`
  width: 100px;
  height: 100px;
  background-color: inherit;
`

const Status = styled.div`
  width: 100%;
  left: 0px;
  top: -24px;
  color: ${({ disabled, theme }) => {
    if (disabled || theme.retailer.default) return '#fff'
    return '#221F20'
  }};   
  padding: 3px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 18px;
`

const ICONS = [
  {
    color: '#8f26a1',
  },
  {
    color: '#57DF57',
  },
  {
    color: '#F59805',
  },
  {
    color: '#55C5FF',
  },
  {
    color: '#9F4BFF',
  },
]

const Badge = ({
  wrpStyle, index, badge, disabled,
}) => {
  const assetsUrl = getParam('assetsUrl')
  const [showModal, setShowModal] = useState()
  const { color } = ICONS[Math.abs(index - (Math.ceil(index / ICONS.length) * ICONS.length))]
  const { name } = badge
  const { image } = useImage(badge?.icon_name)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (showModal) {
      document.querySelector('.App').setAttribute('aria-hidden', true)
    } else document.querySelector('.App').setAttribute('aria-hidden', false)
  }, [showModal])

  return (
    <>
      <Wrapper
        tabIndex="0"
        role="button"
        aria-label={disabled ? name : `${name} Badge. Completed`}
        disabled={disabled}
        style={wrpStyle}
        onClick={toggleModal}
        onKeyDown={pressHotkeys}
      >
         <Status disabled={disabled}>{disabled ? 'not completed' : 'completed'}</Status>
        <Container color={color} disabled={disabled}>
          <InnerContainer>
            <Icon tabIndex="-1" aria-hidden="true" src={image || `${assetsUrl}${badge.icon}`} alt="" />
          </InnerContainer>
        </Container>
        <Title disabled={disabled} tabIndex="-1" aria-hidden="true">
          {name}
        </Title>
      </Wrapper>
      {showModal && createPortal(
        <ModalWrapper>
          <BlueBadge
            icon={image || `${assetsUrl}${badge.icon}`}
            color={color}
            title={name}
            onClose={toggleModal}
            disabled={disabled}
            text={badge?.description}
            linkTitle="Access Section"
            link={badge?.link || (badge.id ? `/badges/${badge.id}` : undefined)}
          />
        </ModalWrapper>, document.body,
      )}
    </>
  )
}

export default Badge
