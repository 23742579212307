import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const RibbonStyles = styled.div`
  width: 136px;
  height: 168px;
  overflow: hidden;
  position: absolute;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid var(--orange);
    border-top-color: transparent;
    border-left-color: transparent;
  }
  &:before {
    top: 0;
    right: 0;
  }
  &:after {
    bottom: 0;
    left: 0;
  }
  span {
    position: absolute;
    display: block;
    width: 290px;
    padding: 5px 0;
    background-color: var(--orange);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: var(--mainTextColor);
    font: 700 12px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    right: -25px;
    top: 7px;
    transform: rotate(-45deg);
  }
`
const Ribbon = ({ text = 'New' }) => <RibbonStyles><span>{text}</span></RibbonStyles>

Ribbon.propTypes = {
  text: PropTypes.string,
}
export default Ribbon
