import {
  useState, useEffect, useRef,
} from 'react'

const useLocalStorageState = (
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) => {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = localStorage.getItem(key)
    if (valueInLocalStorage) return deserialize(valueInLocalStorage)
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const stateStringified = JSON.stringify(state)

  const setItem = (newValue) => {
    setState(newValue)
    window.localStorage.setItem(key, serialize(newValue))
    window.dispatchEvent(new Event('storage', { key }))
  }

  useEffect(() => {
    const handleStorageChange = (e) => {
      const localStorageValue = deserialize(localStorage.getItem(key))

      if (JSON.stringify(localStorageValue) !== stateStringified) {
        setState(localStorageValue)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [deserialize, key, stateStringified])

  const prevKeyRef = useRef(key)

  useEffect(() => {
    const prevKey = prevKeyRef.current
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey)
    }
    prevKeyRef.current = key
    window.localStorage.setItem(key, serialize(state))
  }, [key, serialize, state])

  return [state, setItem]
}
export default useLocalStorageState
