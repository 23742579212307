import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import FocusTrap from 'focus-trap-react'
import CloseCircle from '../assets/CloseCircle.svg'
import CloseCircleBlack from '../assets/CloseCircleBlack.svg'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.retailer.secondaryColor};
  height: 435px;
  width: 395px;
  margin: 175px auto;
  position: relative;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: black dashed 2px;
  outline-offset: -10px;

  @media (max-width: 767px) {
    background-size: 300px;
    width: 315px;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  filter: ${({ theme }) => (theme.retailer.default ? '' : 'invert(1)')};
  height: 107px;
  width: 107px;

  @media (min-width: 360px) and (max-width: 767px) {
    height: 80px;
    width: 80px;
  }
`

const Title = styled.h2`
  text-transform: uppercase;
  color: ${({ theme }) => {
    if (theme.retailer.default) return '#ffffff'
    return '#221F20'
  }};
  font-size: 24px;
  padding: 0 15px;
  margin: 15px 0;
  line-height: 32px;
`

const Text = styled.p`
  color: ${({ theme }) => {
    if (theme.retailer.default) return '#ffffff'
    return '#221F20'
  }};
  font-size: 16px;
  margin: 0 0 30px 0;
  line-height: 20px;
  padding: 0 30px;

  @media (min-width: 360px) and (max-width: 767px) {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
`

const CloseButton = styled.button`
  background: ${({ theme }) =>
    `url(${
      theme.retailer.default ? CloseCircle : CloseCircleBlack
    }) center no-repeat`};
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  border: none;
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;
`

const Button = styled.button`
  width: 285px;
  height: 50px;
  border: 3px solid;
  border-color: ${({ theme }) => {
    if (theme.retailer.default) return '#ffffff'
    return '#221F20'
  }};
  background: transparent;
  border-radius: 8px;
  font-size: 18px;
  text-transform: uppercase;
  color: ${({ theme }) => {
    if (theme.retailer.default) return '#ffffff'
    return '#221F20'
  }};
  font-weight: 700 !important;

  @media (min-width: 360px) and (max-width: 767px) {
    width: 250px;
  }
`

const OrangeBadge = ({
  wrpStyle,
  icon,
  title,
  text,
  btnTitle,
  disabled,
  onClose,
  onClick,
  wrapperRef,
}) => {
  const refClose = useRef(null)

  useEffect(() => {
    if (!refClose) return
    setTimeout(() => {
      refClose.current.focus()
    }, 100)
  }, [])

  return (
    <FocusTrap>
      <Wrapper
        disabled={disabled}
        style={wrpStyle}
        tabIndex='0'
        ref={wrapperRef}
        role='dialog'
        aria-modal='true'
        aria-labelledby='orange_badge_title'
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
      >
        <CloseButton
          ref={refClose}
          aria-label={`Close learn more about ${title} overlay`}
          className='close'
          onClick={onClose}
        />
        <HeadingWrapper>
          <Icon src={icon} alt='' aria-hidden />
          <Title id='orange_badge_title'>{title}</Title>
        </HeadingWrapper>
        <Text>{text}</Text>
        <Button aria-label={`${btnTitle} ${title}`} onClick={onClick}>{btnTitle}</Button>
      </Wrapper>
    </FocusTrap>
  )
}

export default OrangeBadge
