import React, { createContext } from 'react'
import customersObj from './config'
import { getCustomer } from './helpers/utils'

export const ConfigContext = createContext()

export const ConfigContextProvider = (props) => {
  const customer = getCustomer()

  const value = {
    customerConfig: customersObj[customer],
  }

  return (
    <ConfigContext.Provider value={value} >
      {props.children}
    </ConfigContext.Provider>
  )
}
