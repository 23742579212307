export const COLOR = {
  green: '#70d44b',
  white: '#ffffff',
  black: '#221F20',
  blue: '#012979',
  gray: '#5f5f5f',
  orange: '#C74100',
  grayLight: '#BABFC4',
  grayMid: '#717171',
  grayBackground: '#e4eaef',
  darkOrange: '#c64427',
  red: '#c71200',
  webGreen: '#C7E64E',
}
