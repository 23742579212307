import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Badge from '../components/Badge'
import Header from '../components/Header'
import PageSwitcher from '../components/PageSwitcher'
import { hardcodedDataOnPage } from '../constants/assets/data'
import { useConfigContext } from '../hooks'
import { useLocalStorageState } from '../hooks'

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Info = styled.h2`
  color: ${({ theme }) => theme.color.grayText};
  font-size: 14px;
  width: 330px;
  margin: 0 auto 16px auto;
  font-weight: 200;
`

const Title = styled.h1`
  font-size: 22px;
  color: #313334;
  margin-bottom: 16px;
`

const TotalBadges = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  border-radius: 24px;
  background-color: ${({ theme }) =>
    theme.retailer.default ? '#57DF57' : theme.retailer.primaryColor};
  color: #221f20;
  font-size: 16px;
  font-weight: 700;

  width: 75px;
  height: 38px;
`

const BadgesWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
`

const Badges = () => {
  const { customerConfig: { customer } } = useConfigContext()
  const [progress, setProgress] = useLocalStorageState('progress')
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customer].pagesData.badges,
    []
  )

  const displayBadges = () => {
    const availableBadges = [...progress].map((value, index) => (
      <Badge
        key={value.id + index}
        disabled={!value.isDone}
        index={index}
        badge={value}
      />
    ))

    return <BadgesWrapper>{availableBadges}</BadgesWrapper>
  }

  const completed = () => {
    const values = progress.filter((value) => value.isDone === true)
    return values.length
  }

  return (
    <Wrapper>
      <Header pageName={'My Badges'} inverted={true} />
      <PageSwitcher
        navLeft="/explore-more"
        navRight="/learning-curve"
        pageTitle="My badges"
        pageNumber={3}
      />
      <Container as='main' id="main-content">
        <Title>{pagesData.title}</Title>
        <Info>{pagesData.description}</Info>
        <TotalBadges aria-label={`${completed()} Badge Complete out of ${progress.length}.`}>
          <div aria-hidden>
            {completed()}/{progress.length}
          </div>
        </TotalBadges>
      </Container>
      {displayBadges()}
    </Wrapper>
  )
}

export default Badges
