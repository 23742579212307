import React, { createContext, useState, useEffect } from 'react'

import { useConfigContext, useLocalStorageState, useQueryParams } from './hooks'
import { fetchDeviceBySerial } from './services'
import styled, { css } from 'styled-components'
import Loader from './components/Loader'

const Wrapper = styled.div`
  background: ${({ theme }) => `${theme.retailer.backgroundGradient}`} no-repeat,
    url(/images/appBackground.svg);
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;
  overflow: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.p`
  border: 2px solid #e96200;
  color: #e96200;
  font-size: 20px;
  padding: 14px;
  background-color: white;
  text-align: center;
  margin: 4px;
  border-radius: 10px;
`

const fetchDevice = async ({ productSerial, customerConfig }) => {
  if (productSerial === 'testing')
    return {
      referenceId: customerConfig.defaultDeviceReferenceId,
      displayName: 'Testing environment',
    }

  const product = await fetchDeviceBySerial(productSerial)

  if (!product) {
    alert(`The product with serial number "${productSerial}" was not found.
    A default product will be used (${customerConfig.defaultDeviceReferenceId})`)
    return {
      referenceId: customerConfig.defaultDeviceReferenceId,
      displayName: 'Testing environment',
    }
  }

  return {
    serial: productSerial,
    referenceId: product.reference_id,
    displayName: product.vanity_name || product.reference_id,
  }
}

export const DeviceCtx = createContext({
  device: undefined,
})

export const DeviceCtxProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { id, subscriber_id: subscriberId } = useQueryParams()
  const [device, setDevice] = useLocalStorageState('device')
  const { customerConfig } = useConfigContext()
  const deviceReferenceId = device?.referenceId

  useEffect(() => {
    if (subscriberId) localStorage.setItem('subscriber_id', subscriberId)
  }, [subscriberId])

  useEffect(() => {
    if (id)
      fetchDevice({ productSerial: id, customerConfig }).then((res) => {
        setDevice(res)
        setIsLoading(false)
      })
    if (!id) setIsLoading(false)
  }, [id])

  if (!deviceReferenceId) {
    return (
      <Wrapper>
        <Error>The serial number of the device is not selected</Error>
      </Wrapper>
    )
  }

  return (
    isLoading ? <Loader /> : <DeviceCtx.Provider value={device}>{children}</DeviceCtx.Provider>
  )
}
