import styled from 'styled-components'

const Wrapper = styled.div`
  background: #fff;
  min-height: 38px;
  min-width: 110px;
  border-radius: 16px;
  max-width: 182px;
  padding: 0px 18px;
`

export default Wrapper
