import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: ${(props) => (props.close ? 'none' : 'flex')};
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: #000000e8;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`

const ChildrenWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
`

const Overlay = ({
  wrpStyle, children, close, onClick,
}) => (
  <Wrapper close={close} onClick={onClick} style={wrpStyle} aria-hidden='false'>
    <ChildrenWrapper>
      {children}
    </ChildrenWrapper>
  </Wrapper>
)

export default Overlay
