import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import FocusTrap from 'focus-trap-react'
import CloseCircle from '../assets/CloseCircle.svg'
import { titleCase } from '../helpers/utils'

const Wrapper = styled.div`
  outline: dashed;
  outline-offset: -10px;
  outline-width: 2px;
  outline-color: ${({ disabled, theme }) => (theme.retailer.default && !disabled ? '#f59805' : '#7c7c7c')};
  height: 454px;
  width: 454px;
  background-color: ${({ theme }) => theme.retailer.primaryColor};
  margin: 175px auto;
  position: relative;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 475px) {
    height: 346px;
    width: auto;
    margin: 0px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      height: 372px;
      width: 434px;
      background-color: #717171;

      h2,
      p,
      a {
        color: #fff;
        border-bottom: #fff;
      }
    `}
`

const DashedWrapper = styled.div`
  height: ${({ disabled }) => (disabled ? '354px' : '435px')};
  width: ${({ disabled }) => (disabled ? '415px' : '435px')};

  @media (max-width: 475px) {
    height: 325px;
    width: auto;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  margin-top: 25px;
  width: 90%;

  @media (max-width: 720px) {
    height: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ disabled, color, theme }) =>
    disabled ? '#B2B2B2' : theme.retailer.default ? color : '#221f20'};

  border: 2px solid #fff;
  border-radius: 50%;

  width: 120px;
  height: 120px;

  @media (min-width: 360px) and (max-width: 720px) {
    width: 100px;
    height: 100px;
  }
`

const CircledWrapper = styled.div`
  border: 2px solid #fff;
  border-radius: 50%;

  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 360px) and (max-width: 720px) {
    width: 80px;
    height: 80px;
  }
`

const Icon = styled.img`
  height: auto;
  width: auto;
  max-width: 73px;
  max-height: 73px;
  padding: 5px;
`

const Title = styled.h2`
  color: ${({ theme }) => (theme.retailer.lightTheme ? '#f0f0f0' : '#000')};
  text-transform: uppercase;

  font-size: 24px;
  font-weight: 700;

  margin: 0px;
  padding-bottom: 10px;
  justify-self: center;
  align-self: center;
  line-height: 30px;

  text-align: left;
  max-width: 170px;

  @media (max-width: 720px) {
    max-width: none;
    text-align: center;
  }
`

const Text = styled.p`
  width: 83%;
  color: ${({ theme }) => (theme.retailer.lightTheme ? '#f0f0f0' : '#000')};
  font-size: 16px;
  line-height: 20px;
`

const CloseButton = styled.button`
  cursor: pointer;
  background: url(${CloseCircle});
  filter: ${({ theme }) => theme.retailer.default ? '' : 'invert(1)'};
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  border: none;
  position: absolute;
  top: 20px;
  right: 22px;
`

const Button = styled(Link)`
  text-transform: uppercase;
  color: ${({ theme }) => (theme.retailer.default ? '#f59805' : '#000000')};
  font-weight: 400 !important;
  font-size: 16px !important;
  padding-top: 10px;
  text-decoration: underline;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.retailer.default ? '#f59805' : '#000000'}`};
`

const EmptyWhitespace = styled.div`
  height: 35px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`

const BlueBadge = ({
  wrpStyle,
  icon,
  color,
  title,
  text,
  linkTitle,
  disabled,
  onClose,
  link,
}) => {
  const refClose = useRef(null)

  useEffect(() => {
    if (!refClose) return
    setTimeout(() => {
      refClose.current.focus()
    }, 100)
  }, [])
  return (
    <FocusTrap>
      <Wrapper
        disabled={disabled}
        style={wrpStyle}
        tabIndex='0'
        ref={(input) => input && input.focus()}
        role='dialog'
        aria-modal='true'
        aria-labelledby='blue_badge_title'
        onClick={onClose}
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
      >
        <DashedWrapper disabled={disabled}>
          <CloseButton
            ref={refClose}
            aria-label={`Close ${title} overlay`}
            className='close'
            onClick={onClose}
          />
          <Content>
            <HeadingWrapper>
              <IconWrapper color={color} disabled={disabled}>
                <CircledWrapper>
                  <Icon aria-hidden='true' src={icon} alt={''} />
                </CircledWrapper>
              </IconWrapper>
              <Title>{title}</Title>
            </HeadingWrapper>
            <Text>{text}</Text>
            {link && linkTitle ? (
              <Button to={link} aria-label={`Access ${title} Section`}>
                {linkTitle}
              </Button>
            ) : (
              <EmptyWhitespace></EmptyWhitespace>
            )}
          </Content>
        </DashedWrapper>
      </Wrapper>
    </FocusTrap>
  )
}

export default BlueBadge
