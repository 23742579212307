import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import usePageTracking from '../hooks/usePageTracking'
import { useConfigContext } from '../hooks'
import { loadScript } from '../helpers/scripts'
import { useControlBot } from '../hooks/useControlBot'

const SrOnly = styled.div`
  position: absolute;
  height: 0px;
  width: 0px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
`

const Layout = ({ children }) => {
  const { customerConfig } = useConfigContext()
  const { pathname } = useLocation()
  const { sendBotpressPendingMessage } = useControlBot()
  const pathsIgnoredByBot = ['/', '/loading', '/registration', '/welcome']
  let buttons = []
  let i = 0

  const handleKeyPress = ({ key }) => {
    if (key === 'b') {
      buttons &&
        buttons.forEach((button, index) => {
          if (index === i) {
            button.focus()
          }
          if (i >= buttons.length) {
            i = -1
          }
        })
      i++
    }
  }

  const handleLoadScript = () => {
    loadScript(customerConfig.chatBotLink, function () {
      loadScript(customerConfig.chatBotLink, function () {
        if (!window?.DBLauncher) return
        window.DBLauncher.init({
          customer: customerConfig.botCustomer,
          appleBusinessId: process.env.REACT_APP_APPLE_BUSINESS_ID,
          bubbleMessage: "Let's Chat!",
        })
      })
    })
  }

  const botEventListener = (event) => {
    if (event.data.name === 'webchatOpened') {
      sendBotpressPendingMessage()
    }
  }

  useEffect(() => {
    window.addEventListener('message', botEventListener)
    return () => {
      window.removeEventListener('message', botEventListener)
    }
  }, [])

  useEffect(() => {
    const botBlock = document.getElementById('bp-web-widget')
    if (pathsIgnoredByBot.find((path) => path === pathname)) {
      if (botBlock) botBlock.style.display = 'none'
      return
    }
    if (botBlock) {
      botBlock.style.display = 'block'
      return
    }
    handleLoadScript()
  }, [pathname])

  useEffect(() => {
    buttons = document.querySelectorAll('button')
    document.addEventListener('keydown', handleKeyPress)

    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  usePageTracking()
  return (
    <div style={{ height: '100vh' }}>
      {children}
        <div>
          <SrOnly tabIndex="-1" aria-hidden="true">
            {customerConfig.customerModalName} Bot is available for you last on the page
          </SrOnly>
        </div>
    </div>
  )
}

export default Layout
