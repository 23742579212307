import React, { useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { pressHotkeys } from '../helpers/utils'
import TutorialImage from './TutorialImage'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 56px;
  top: 80px;
  padding-bottom: 10px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0px 12px;
  width: 100%;
  flex-flow: column;
  h1 {
    font-size: 20px;
    color: #012979;
    text-transform: uppercase;
    margin: 6px;
  }
`
const Button = styled.button`
  border: none;
  background: none;
  padding: 1px;
  :focus,
  :focus-visible {
    outline-offset: 0px;
    outline-color: black;
  }
`

const WrapperStepArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ArrowButtonIcon = styled.div`
  width: 25px;
  height: 25px;
  background: ${({ theme }) =>
    theme.retailer.default ? '#fff' : theme.retailer.primaryColor};
  border: none;
  border-radius: 14px;
  transform: ${({ inverted }) =>
    inverted ? 'rotate(180deg)' : 'rotate(0deg)'};
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &:after {
    content: '';
    height: 8px;
    width: 8px;
    right: 9px;
    position: absolute;
    border-top: #fff solid 2px;
    border-left: #fff solid 2px;
    transform: rotate(135deg);

    ${({ theme }) =>
      theme.retailer.default &&
      css`
        border-top: ${theme.retailer.primaryColor} solid 2px;
        border-left: ${theme.retailer.primaryColor} solid 2px;
      `}
  }
`

const WrapperTitle = styled.div`
  flex-flow: row;
  display: flex;
  justify-content: center;
  width: 90%;
  overflow: hidden;
`

const Title = styled.div`
  padding: 16px 15px;
  font-weight: 700;
  transition: 0.2s all ease;
  align-items: center;
  justify-content: center;
  margin: 0px;
  display: block;
  font-size: 18px;
  background: none;
  border-radius: 0;
  color: #221f20;
`

const OptionsWrapper = styled.div`
  margin: 0;
  width: 100%;
`

const StepTextWrapper = styled.div`
  min-height: 60px;
  background: white;
  border-radius: 9px;
  text-align: initial;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  padding: 10px 0 20px;
  div {
    color: ${(props) => (props.active ? '#221F20' : '#5D5D5D')};
    font-size: 14px;
    line-height: 20px;
    margin: 0 15px 0 15px;
    span {
      p {
        margin: 0 0 30px 0;
      }
    }
  }
  width: 100%;
  margin: 0px 0px 20px 0px;
`

const Number = styled.p`
  min-width: 84px;
  height: 44px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  margin: 0 10px;
  width: 100%;
  span {
    color: ${({ active, theme }) =>
      active && theme.retailer.primaryColor !== '#012979'
        ? '#221F20'
        : '#FFFFFF'};
    font-size: 15px;
  }
`

const SliderDotWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto 15px auto;
`

const SliderDot = styled.button`
  border: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: ${({ isCurrentStep, theme }) =>
    isCurrentStep ? theme.retailer.primaryColor : '#a6a6a6'};
  border-radius: 50%;
`

const TutorialMobileView = ({
  nextSubstep,
  prevSubstep,
  tutorialTitle,
  filteredSteps,
  displayedStepNumber,
  inputRef,
  isFetching,
  parseHTML,
  tooltipOptions,
  changeActiveStep,
  currentStep,
  steps,
  extractContent,
}) => {
  const mainMenuTabRef = useRef()
  const learningCurveRef = useRef()
  const option = filteredSteps.find(
    (item) => displayedStepNumber === item.order,
  )

  const handleKeyPress = useCallback(({ key }) => {
    if (key === 'Home') {
      mainMenuTabRef.current.focus()
    }
    if (key === 'End') {
      learningCurveRef.current.focus()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  return (
    <Wrapper>
      <ButtonWrapper>
        <WrapperStepArrow>
          <WrapperTitle>
            <Title aria-label={tutorialTitle} ref={mainMenuTabRef}>
              {tutorialTitle}
            </Title>
          </WrapperTitle>
        </WrapperStepArrow>
        {!isFetching && (
          <OptionsWrapper>
            <StepTextWrapper
              id={`tutorial-step-${displayedStepNumber}`}
              className="tutorial-step"
              active={true}
              ref={inputRef}
              tabIndex="0"
            >
              <Number active={true}>
                <Button
                  aria-label={'Previous Step'}
                  role="button"
                  onClick={prevSubstep}
                  onKeyDown={pressHotkeys}
                >
                  <ArrowButtonIcon inverted={true} />
                </Button>
                <span
                  tabIndex="0"
                  aria-label={`Step ${displayedStepNumber} of ${
                    filteredSteps.length
                  } ${filteredSteps[displayedStepNumber - 1].tutorial.name}`}
                >
                  Step {displayedStepNumber} of {filteredSteps.length}
                </span>
                <Button
                  aria-label="Next Step"
                  role="button"
                  onClick={nextSubstep}
                  onKeyDown={pressHotkeys}
                >
                  <ArrowButtonIcon />
                </Button>
              </Number>
              <div
                tabIndex="0"
                aria-label={`${extractContent(option.tutorialstep.step)}`}
              >
                <span>
                  {parseHTML(option.tutorialstep.step, tooltipOptions)}
                </span>
              </div>
            </StepTextWrapper>
          </OptionsWrapper>
        )}
      </ButtonWrapper>
      <SliderDotWrapper
        tabIndex="0"
        aria-label={`carousel containing ${filteredSteps.length} slides`}
      >
        {filteredSteps.map((_, index) => (
          <SliderDot
            tabIndex="0"
            aria-label={`${index + 1} step ${
              displayedStepNumber - 1 === index ? 'pressed' : 'unpressed'
            }`}
            key={index}
            onClick={() => {
              changeActiveStep(index + 1)
            }}
            onKeyDown={pressHotkeys}
            isCurrentStep={displayedStepNumber - 1 === index}
          />
        ))}
      </SliderDotWrapper>
      <TutorialImage
        nextSubstep={nextSubstep}
        currentStep={currentStep}
        steps={steps}
      />
    </Wrapper>
  )
}

export default TutorialMobileView
