import React, { useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

const PhoneContainer = styled.div`
  cursor: pointer;
  transform-origin: 50% 50%;
  position: relative;
  width: ${({ frameWidth }) => (frameWidth ? `${frameWidth}px` : 'auto')};
  margin: 0 auto;
`
const ImageWrapper = styled.div`
  float: left;
  margin-bottom: 0px;
  transform: rotate(0deg) translate3d(0px, 0px, 0px);
  width: ${({ frameWidth }) => (frameWidth ? `${frameWidth}px` : 'auto')};
`
const PhoneFrame = styled.img`
  position: relative;
  z-index: 2;
  vertical-align: middle;
`
const ScreenPhoneImage = styled.img`
  position: relative;
  float: left;
  top: ${({ offsetTop }) => `${offsetTop}px`};
  left: ${({ offsetLeft }) => `${offsetLeft}px`};
  width: ${({ XSize }) => XSize}px;
  height: ${({ YSize }) => YSize}px;
  margin-bottom: ${({ offsetTop }) => `${offsetTop}px`};
  z-index: ${({ ZPosition }) => ZPosition + 1};
  display: ${({ offsetTop }) => !offsetTop && 'none'};
`
const TouchButtonContainer = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 9;
  display: block;
  left: ${({ ButtonXPos }) => ButtonXPos}px;
  top: ${({ ButtonYPos }) => ButtonYPos}px;
  transition: 0.3s all ease;
  opacity: ${({ ButtonXPos }) => (ButtonXPos ? '1' : '0')};
  animation: ${({ Swap }) =>
    Swap
      ? css`
          ${Swap} infinite 1s linear
        `
      : ''};
  box-shadow: ${({ BoxShadow }) =>
    BoxShadow
      ? css`
          ${BoxShadow}
        `
      : 'none'};
  border-radius: 100%;

  button {
    cursor: pointer;
    background-color: rgba(69, 133, 49, 0.8);
    border: 2px solid white;
    height: 32px;
    width: 32px;
    position: relative;
    top: 0;
    left: 0;
    outline-offset: 2px;
    border-radius: 100%;
    box-shadow: 5px 5px 10px 0 #0000006b;
  }
`

const TutorialImage = ({ nextSubstep, currentStep, steps }) => {
  const [frameProperties, setFrameProperties] = useState({
    wrapperWidth: 0,
    homeScreenOffsetTop: 0,
  })
  const phoneFrameRef = useRef()

  const borderPhone = steps[0]?.phoneScreenImage

  const imageUrl = currentStep?.tutorialstep.imageLocation

  const buttonXPos =
    currentStep?.action?.on_screen === true
      ? currentStep?.buttonScreenImages[0].buttonXUpperLeft +
        borderPhone?.screenXUpperLeft
      : currentStep?.buttonScreenImages[0].buttonXUpperLeft

  const buttonYPos =
    currentStep?.action?.on_screen === true
      ? currentStep?.buttonScreenImages[0].buttonYUpperLeft +
        borderPhone?.screenYUpperLeft
      : currentStep?.buttonScreenImages[0].buttonYUpperLeft

  const keyframesUp = keyframes`
      from { transform: translateX(0px) translateY(0px); opacity: 0.4 }
      to { transform: translateX(0px) translateY(-70px); opacity: 1 }
    `
  const keyframesDown = keyframes`
      from { transform: translateX(0px) translateY(0px) }
      to { transform: translateX(0px) translateY(70px) }
    `
  const keyframesRight = keyframes`
      from { transform: translateX(0px) translateY(0px) }
      to { transform: translateX(70px) translateY(0px) }
    `
  const keyframesLeft = keyframes`
      from { transform: translateX(0px) translateY(0px) }
      to { transform: translateX(-70px) translateY(0px) }
    `

  const getAnimationInfo = (actionType) => {
    switch (actionType) {
      case 'swipe_up':
        return keyframesUp
      case 'swipe_down':
        return keyframesDown
      case 'swipe_left':
        return keyframesLeft
      case 'swipe_right':
        return keyframesRight
      default:
        return ''
    }
  }

  const getBoxShadowAnimation = (actionType) => {
    switch (actionType) {
      case 'swipe_up':
        return 'rgba(0, 0, 0, 0.8) 0px 10px 20px 0px, rgba(0, 0, 0, 0.8) 0px 30px 20px 0px, rgba(0, 0, 0, 0.8) 0px 50px 20px 0px, rgba(0, 0, 0, 0.8) 0px 70px 20px 0px'
      case 'swipe_down':
        return 'rgba(0, 0, 0, 0.8) 0px -10px 20px 0px, rgba(0, 0, 0, 0.8) 0px -30px 20px 0px, rgba(0, 0, 0, 0.8) 0px -50px 20px 0px, rgba(0, 0, 0, 0.8) 0px -70px 20px 0px'
      case 'swipe_left':
        return 'rgba(0, 0, 0, 0.8) 10px 0px 20px 0px, rgba(0, 0, 0, 0.8) 30px 0px 20px 0px, rgba(0, 0, 0, 0.8) 50px 0px 20px 0px, rgba(0, 0, 0, 0.8) 0px 0px 20px 0px'
      case 'swipe_right':
        return 'rgba(0, 0, 0, 0.8) -10px 0px 20px 0px, rgba(0, 0, 0, 0.8) -30px 0px 20px 0px, rgba(0, 0, 0, 0.8) -50px 0px 20px 0px, rgba(0, 0, 0, 0.8) -70px 0px 20px 0px'
      default:
        return 'none'
    }
  }

  return (
    <PhoneContainer
      frameWidth={frameProperties.wrapperWidth}
      onClick={nextSubstep}
    >
      <ImageWrapper frameWidth={frameProperties.wrapperWidth}>
        <PhoneFrame
          onLoad={() => {
            setFrameProperties({
              wrapperWidth: phoneFrameRef.current.offsetWidth,
              homeScreenOffsetTop:
                -phoneFrameRef.current.offsetHeight +
                borderPhone?.screenYUpperLeft,
            })
          }}
          src={borderPhone?.imageLocation}
          ref={phoneFrameRef}
          alt=""
          aria-hidden
        />
        <ScreenPhoneImage
          alt="Current phone"
          loading="lazy"
          src={imageUrl}
          offsetTop={frameProperties.homeScreenOffsetTop}
          offsetLeft={borderPhone?.screenXUpperLeft}
          XSize={borderPhone?.screenXSize}
          YSize={borderPhone?.screenYSize}
          ZPosition={borderPhone?.faceplate_behind}
        />
      </ImageWrapper>
      {currentStep?.action.type !== 'automate' && (
        <TouchButtonContainer
          ButtonXPos={buttonXPos}
          ButtonYPos={buttonYPos}
          Swap={getAnimationInfo(currentStep?.action?.type)}
          BoxShadow={getBoxShadowAnimation(currentStep?.action?.type)}
        >
          <button tabIndex="0" aria-hidden="true" />
        </TouchButtonContainer>
      )}
    </PhoneContainer>
  )
}

export default TutorialImage
