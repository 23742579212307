import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  init,
  browserTracingIntegration,
  captureConsoleIntegration,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react'
import { createBrowserHistory } from 'history'

if (process.env.NODE_ENV !== 'development') {
  const history = createBrowserHistory()

  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_INSTANCE_ENVIRONMENT,
    silent: !process.env.CI,
    integrations: [
      browserTracingIntegration(),
      reactRouterV5BrowserTracingIntegration({ history }),
      replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      captureConsoleIntegration({ levels: ['error'] }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
