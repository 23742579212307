import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import GreenDotted from '../assets/GreenDotted.svg'
import LoadingOne from '../assets/LoadingOne.svg'
import LoadingTwo from '../assets/LoadingTwo.svg'
import LoadingThree from '../assets/LoadingThree.svg'
import LoadingFour from '../assets/LoadingFour.svg'
import { pressHotkeys } from '../helpers/utils'

export const PROGRESS_IMAGE = {
  0: LoadingOne,
  25: LoadingTwo,
  50: LoadingThree,
  75: LoadingFour,
  100: '',
}

const WrapperLink = styled(NavLink)`
  height: 200px;
  width: 168px;
  border-radius: 16px;

  margin: 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  background: url(${GreenDotted}) center no-repeat;

  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);

  @media (max-width: 450px) {
    height: 156px;
    width: 129px;
    margin: 5px;
  }
`

const Icon = styled.img`
  max-width: 73px;
  max-height: 73px;
  width: auto;
  height: auto;
  padding: 5px;
`

const Text = styled.span`
  display: block;
  text-transform: uppercase;
  color: #221f20;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  width: 85%;
  margin: 16px auto 0 auto;
`

export const LoadingWrapper = styled.div`
  background: ${({ progress }) => `url(${PROGRESS_IMAGE[progress]}) center no-repeat`};
  background-size: 103px;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.retailer.successColor};
  width: 106px;
  height: 106px;
  border: ${({ theme }) => `3px solid ${theme.retailer.icon.color}`};

  @media (max-width: 450px) {
    height: 100px;
    width: 100px;
  }
`

export const LoadingCircle = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.retailer.icon.color};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    height: 78px;
    width: 78px;
  }
`

export const ProgressCircle = styled.div`
  border-radius: 50%;
  position: absolute;
  width: 73px;
  height: 73px;
  border-color: transparent;

  transition: 0.3s all ease;

  transform: rotate(45deg);
`

export const Percent = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50px;
  background: #ffffff;
  border: 2px solid #b93924;
  position: absolute;
  bottom: 8px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    font-size: 11px;
  }
`

const Badge = ({ wrpStyle, icon, title, onClick, progress }) => (
  <WrapperLink
    role='link'
    tabIndex="0"
    aria-label={`${title} ${progress}% tutorial completed`}
    to={onClick}
    style={wrpStyle}
    onKeyDown={pressHotkeys}
  >
    <LoadingWrapper progress={progress || 0}>
      <LoadingCircle>
        <ProgressCircle />
        <Icon src={icon} height={60} width={60} alt="" aria-hidden />
        {progress !== undefined ? (
          <Percent>
            <span aria-hidden="true">{progress}%</span>
          </Percent>
        ) : null}
      </LoadingCircle>
    </LoadingWrapper>

    <Text aria-hidden="true">{title}</Text>
  </WrapperLink>
)

export default Badge
