import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import './App.css'
import 'intro.js/introjs.css'
import Routes from './routes/routes'
import { UserContextProvider } from './UserContext'
import Layout from './components/Layout'
import theme from './constants/theme'
import { ConfigContextProvider } from './ConfigContext'
import { ContentCtxProvider } from './ContentCtx'
import { OptionsCtxProvider } from './OptionsCtx'
import { BadgesCtxProvider } from './BadgesCtx'
import { TimeSpentContextProvider } from './TimeSpentCtx'
import { DeviceCtxProvider } from './DeviceCtx'
import ErrorBoundary from './components/ErrorBoundary'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <OptionsCtxProvider>
            <ContentCtxProvider>
              <ConfigContextProvider>
                <BrowserRouter>
                  <DeviceCtxProvider>
                    <BadgesCtxProvider>
                      <TimeSpentContextProvider>
                        <Layout>
                          <Routes />
                        </Layout>
                      </TimeSpentContextProvider>
                    </BadgesCtxProvider>
                  </DeviceCtxProvider>
                </BrowserRouter>
              </ConfigContextProvider>
            </ContentCtxProvider>
          </OptionsCtxProvider>
        </UserContextProvider>
      </ThemeProvider>
    </div>
  )
}

const WithErrorBoundaryApp = (props) => (
  <ErrorBoundary>
    <App {...props} />
  </ErrorBoundary>
)

export default WithErrorBoundaryApp
