import React, { useState, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import TutorialButton from '../../../components/TutorialButton'
import TutorialSteps from './TutorialSteps'
import PageSwitcher from '../../../components/PageSwitcher'
import { BadgesCtx } from '../../../BadgesCtx'
import { findClosestProgressValue } from '../../../constants/badgeProgress'
import BreadCrumbs from '../../../components/BreadCrumbs'
import {
  LoadingCircle,
  LoadingWrapper,
  PROGRESS_IMAGE,
  Percent,
  ProgressCircle,
} from '../../../components/LearningBadge'
import { getParam } from '../../../helpers/parameters'

export const Wrapper = styled.section`
  background: white;
  font-family: 'Open Sans', sans-serif;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Icon = styled.img`
  max-width: 73px;
  max-height: 73px;
  width: auto;
  height: auto;
  padding: 5px;
`

const ContentWrapper = styled.div`
  width: 515px;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: 100%;
  }
`

const GradientBackground = styled.div`
  margin-bottom: 50px;
`

const Gradient = styled.div`
  text-align: left;
  display: flex;
  flex-flow: row;
  margin: 20px 0 15px 10px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding-top: 18px;
  width: 55%;
  margin-left: 22px;
  font-size: 14px;

  h1 {
    font-size: 22px;
    color: black;
    margin: 0px;
    margin-bottom: 20px;
  }

  span {
    font-size: 16px;
  }
`

const Procents = styled.div`
  width: 150px;
  height: 92px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 16px;
    transform: rotate(315deg);
  }
`

const TutorialsWrapper = styled.div`
  margin-top: 15px;
  padding-bottom: 20px;
`

const Tutorials = ({ closeTutorial, badge, tutorials }) => {
  const assetsUrl = getParam('assetsUrl')
  const badgeCtx = useContext(BadgesCtx)
  const [stepsId, setStepsId] = useState(null)
  const [completedStep, setCompletedStep] = useState(false)
  const { name: categoryName, description: categoryDescription, icon } = 
    badgeCtx.getCategoryById(badge)
  const displayTutorials = () => {
    const options = tutorials
      .map((tut) =>
          <TutorialButton
            to={`/tutorials/${tut.tutorial.serial}`}
            key={tut.id}
            title={tut.tutorial.title}
            completed={tut?.isDone}
            onClick={() => {
              badgeCtx.setCurrentTaskId(tut.id)
            }}
            text={tut.description}
          />)

    return <div>{options}</div>
  }

  const goBack = () => {
    closeTutorial(true)
  }
  const historyPages = useMemo(
    () => [
      { title: 'Main Menu', link: '/main-menu' },
      {
        title: badgeCtx.currentCategory.name,
        link: `/badges/${badgeCtx.currentCategory.id}`,
      },
    ],
    [badgeCtx]
  )
  return (
    <Wrapper aria-label="There is new information on the screen">
      <>
        <PageSwitcher
          navLeft="/explore-more"
          navRight="/learning-curve"
          pageTitle="My badges"
          pageNumber={1}
        />
        <nav><BreadCrumbs historyPages={historyPages} /></nav>
        {stepsId ? (
          <TutorialSteps
            id={stepsId}
            completedStep={completedStep}
            badgeId={badge.id}
            goBack={goBack}
          />
        ) : (
          <ContentWrapper id="main-content" role='main'>
            <GradientBackground>
              <Gradient>
              <LoadingWrapper progress={badgeCtx.currentCategory.progress || 0}>
                <LoadingCircle>
                  <ProgressCircle aria-label={`${badgeCtx.currentCategory.progress} percent of ${badgeCtx.currentCategory.name} tutorial completed`} />
                  <Icon src={`${assetsUrl}${icon}`} height={60} width={60} alt="" aria-hidden />
                  <Percent>
                    <span
                      aria-hidden
                      aria-label={`${badgeCtx.currentCategory.progress}% of ${categoryName} tutorial completed.`}
                      title={`${badgeCtx.currentCategory.progress}% of the course completed.`}
                    >
                      {badgeCtx.currentCategory.progress}%
                    </span>
                  </Percent>
                </LoadingCircle>
              </LoadingWrapper>
                <TextWrapper>
                  <h1>{categoryName}</h1>
                  <div>{categoryDescription}</div>
                </TextWrapper>
              </Gradient>
            </GradientBackground>
            <TutorialsWrapper>
              {displayTutorials()}
            </TutorialsWrapper>
          </ContentWrapper>
        )}
      </>
    </Wrapper>
  )
}

export default Tutorials
