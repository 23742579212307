import React, {
  createContext,
  useContext,
  useEffect,
} from 'react'
import { useLocalStorageState } from './hooks'
import { fetchUpdateCustomer } from './services'
import { UserContext } from './UserContext'

const PERSIST_INTERVAL_SECONDS = 60
const TRACK_INTERVAL = 1000

export const TimeSpentContext = createContext()

export const TimeSpentContextProvider = (props) => {
  const user = useContext(UserContext)
  const [timeSpent, setTimeSpent] = useLocalStorageState('timeSpentSeconds', user.points || 1)

  useEffect(() => {
    if (timeSpent < user.points) {
      setTimeSpent(user.points)
    }
  }, [setTimeSpent, timeSpent, user.points])

  useEffect(() => {
    if (timeSpent % PERSIST_INTERVAL_SECONDS === 0 && user.id) {
      fetchUpdateCustomer({
        customerId: user.id,
        customerData: {
          points: timeSpent,
        },
      })
    }
  }, [timeSpent, user])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSpent(timeSpent + 1)
    }, TRACK_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [setTimeSpent, timeSpent])

  const value = {
    timeSpent,
    setTimeSpent,
  }

  return (
    <TimeSpentContext.Provider value={value} >
      {props.children}
    </TimeSpentContext.Provider>
  )
}
