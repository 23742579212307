import React from 'react'
import styled from 'styled-components'
import TutorialImage from './TutorialImage'
import NavButton from './NavButton'
import { pressHotkeys } from '../helpers/utils'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
`

const Section = styled.div`
  height: 600px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin: 30px auto;
  min-height: 0;
  overflow-x: hidden;
`

const BottomBlock = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  min-height: 300px;
  margin-left: 70px;
`

const StepTextWrapper = styled.div`
  width: 456px;
  min-height: 60px;
  background: white;
  border-radius: 9px;
  text-align: initial;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  margin: 15px 0px 20px 0px;
  padding: 10px 0 20px;

  div {
    color: ${(props) => (props.active ? '#221F20' : '#5D5D5D')};
    font-size: 14px;
    line-height: 20px;
    width: 65%;
    margin: 0 10px 0 15px;

    span {
      p {
        margin: 0 0 30px 0;
      }
    }
  }
`

const Number = styled.p`
  min-width: 84px;
  height: 44px;
  background: ${({ active, theme }) =>
    active ? theme.retailer.primaryColor : '#5D5D5D'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  margin: 0 10px;

  span {
    color: ${({ active, theme }) =>
      active && theme.retailer.primaryColor !== '#012979'
        ? '#221F20'
        : '#FFFFFF'};
    font-size: 24px;
  }
`

const OptionsWrapper = styled.div`
  width: 90%;
  margin: -15px 3px 6px 25px;
`

const TutorialDesktopView = ({
  displayedStepNumber,
  nextSubstep,
  currentStep,
  steps,
  isFetching,
  filteredSteps,
  changeActiveStep,
  inputRef,
  navButtonClick,
  isSubmitting,
  stepNumber,
  completedStep,
  tooltipOptions,
  parseHTML,
  extractContent,
}) => {
  const getStepButtonText = () => {
    if (stepNumber === steps.length && completedStep) return 'Go Back'
    if (stepNumber === steps.length && !completedStep) {
      return 'Complete Tutorial'
    }
    return 'Next Step'
  }

  const handleTutorialContentClick = (e) => {
    if (e.target.href) {
      if (e.target.href.indexOf(window.location.host) === -1) {
        e.preventDefault()
        window.open(e.target.href, '_blank')
      }
    }
  }

  return (
    <>
      <Container>
        <TutorialImage
          nextSubstep={nextSubstep}
          currentStep={currentStep}
          steps={steps}
        />
        <Section id="main-content">
          <BottomBlock>
            {!isFetching && (
              <OptionsWrapper>
                {filteredSteps.map((value, index) => (
                  <StepTextWrapper
                    role="button"
                    id={`tutorial-step-${index + 1}`}
                    className="tutorial-step"
                    active={index + 1 === displayedStepNumber}
                    key={`tutorial-step-${index}`}
                    onClick={(e) => {
                      changeActiveStep(index + 1)
                      handleTutorialContentClick(e)
                    }}
                    ref={index + 1 === displayedStepNumber ? inputRef : null}
                    onKeyDown={pressHotkeys}
                    aria-label={`Step ${index + 1} ${extractContent(
                      value.tutorialstep.step,
                    )}`}
                    tabIndex="0"
                  >
                    <Number
                      active={index + 1 === displayedStepNumber}
                    >
                      <span aria-hidden>{index + 1}</span>
                    </Number>
                    <div
                      aria-label={`${extractContent(value.tutorialstep.step)}`}
                    >
                      <span aria-hidden>
                        {parseHTML(value.tutorialstep.step, tooltipOptions)}
                      </span>
                    </div>
                  </StepTextWrapper>
                ))}
              </OptionsWrapper>
            )}
          </BottomBlock>
        </Section>
      </Container>
      <NavButton
        onClick={() => {
          if (displayedStepNumber === filteredSteps.length) {
            navButtonClick()
            return
          }
          nextSubstep()
        }}
        title={getStepButtonText()}
        disabled={isSubmitting}
      />
    </>
  )
}

export default TutorialDesktopView
