import React, {
  useState, useContext, useRef, useMemo,
} from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import NavButton from '../components/NavButton'
import Header from '../components/Header'
import Input from '../components/Input'
import Spacer from '../components/Spacer'
import { COLOR } from '../constants/color'
import Man from '../assets/Man.svg'
import ManUnsign from '../assets/ManUnsign.svg'
import Woman from '../assets/Woman.svg'
import WomanUnsign from '../assets/WomenUnsign.svg'
import Mark from '../assets/Mark.svg'
import { UserContext } from '../UserContext'
import { DeviceCtx } from '../DeviceCtx'
import { postAnalytics } from '../services/analytics'
import { Welcome } from '../components/Icons'
import { convertHTMLtoText, pressHotkeys } from '../helpers/utils'
import { hardcodedDataOnPage } from '../constants/assets/data'
import { useConfigContext } from '../hooks'

const Wrapper = styled.div`
  height: 100%;
`

const Gradient = styled.div`
  margin: 70px 0px 25px 10px;
  text-align: center;
  padding: 20px 0px 0px 25px;
  ${(props) => props.theme.media.iphone7(css`
      padding: 10px 0px 0px 25px;
    `)}

  svg {
    padding-bottom: 20px;
    padding-right: 30px;
    fill: ${({ theme }) => theme.retailer.icon.color};
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    color: ${({ theme }) => (theme.retailer.default ? '#012979' : '#221F20')};
    margin: 0px;
  }
`

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  color: ${({ theme }) => (theme.retailer.default ? '#012979' : '#221F20')};

  ${(props) => props.theme.media.iphone7(css`
      margin-bottom: 5px;
    `)}
`

const Warning = styled(Text)`
  color: ${COLOR.orange};
  width: 280px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
`

const UsersContainer = styled.div`
  margin-top: 40px;
  box-shadow: none;
`

const GenderIconsWrapper = styled.div`
  display: flex;
  max-width: 350px;
  width: 100%;
  height: 110px;
  justify-content: space-around;
  margin: 0 auto;
  align-items: center;

  svg {
    fill: black;
  }

  ${(props) => props.theme.media.iphone7(css`
      height: 95px;
    `)}
`

const BtnIcon = styled.button`
  background-color: unset;
  border: 0;
  img {
    cursor: pointer;
    float: left;
    width: 70px;
  }
`

const CircleChange = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  cursor: pointer;

  border: ${({ theme }) => `4px solid ${
    theme.retailer.default ? theme.retailer.primaryColor : 'black'
  }`};
`

const CheckMark = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => (theme.retailer.default ? theme.retailer.primaryColor : 'black')};
  position: absolute;
  left: 50px;
  top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  box-shadow: none;
`

const Registration = () => {
  const history = useHistory()
  const { referenceId } = useContext(DeviceCtx)
  const { customerConfig } = useConfigContext()
  const [gender, setGender] = useState('')
  const [name, setName] = useState('')
  const [isValidateError, setIsValidateError] = useState(false)

  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.registration,
    [],
  )

  const isWoman = gender === 'female'
  const isMan = gender === 'male'
  const { setUser } = useContext(UserContext)
  const manIcon = useRef(null)

  const getName = (value) => {
    if (!value) {
      setGender('')
    }
    setName(value)
  }

  const getAltAttribute = (genderId) => {
    if (!gender) return genderId === 'man' ? 'I am a man' : 'I am a female'

    if (genderId === 'man') {
      return isMan ? 'Male, selected' : 'Male, not selected'
    }
    return isWoman ? 'Female, selected' : 'Female, not selected'
  }

  const onConfirm = async () => {
    postAnalytics({
      category: 'setup',
      data: {
        user_gender: gender,
        device_model: referenceId,
        customer: customerConfig.customer,
      },
    })

    if (name && gender) {
      setUser({ name, gender })
      history.push('/loading')
    }
  }

  const validationName = (value) => {
    const regex = /[^A-Z\s]/gi
    if (!value.trim()) return false
    if (value.length > 23) return false
    const isInvalidValue = Boolean(value.match(regex))
    if (isInvalidValue) return false
    return true
  }

  const onBlurInput = () => {
    if (!validationName(name)) {
      setIsValidateError(true)
      return
    }
    setIsValidateError(false)
  }

  const onReadyBtnClick = () => {
    if (!validationName(name)) {
      setIsValidateError(true)
      return
    }
    onConfirm()
  }

  return (
    <Wrapper>
      <Header inverted={true} notShow={true} pageName={'Registration'} />
      <main>
        <Spacer height='70' />
        <InputContainer className='animate__animated animate__fadeIn'>
          <Gradient>
            <Welcome />
            <h1
              className='title'
              aria-label={convertHTMLtoText(pagesData.title)}
              autoFocus
              style={{ paddingRight: '27px' }}
            >
              <div
                aria-hidden
                dangerouslySetInnerHTML={{ __html: pagesData.title }}
              ></div>
            </h1>
          </Gradient>
          <Text>What's your name?</Text>
          <Input
            autoFocus
            aria-label='Enter your name'
            placeholder='Enter your name'
            onChange={(e) => {
              getName(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && name) manIcon.current.focus()
            }}
            onBlur={onBlurInput}
            wrpStyle={{ marginBottom: 10 }}
          />
          {isValidateError && (
            <Warning>{`The name must be less than 23
          characters and must not contain numbers and special characters(!№;%:?*~... etc.)`}</Warning>
          )}
        </InputContainer>
        {name && (
          <UsersContainer className='animate__animated animate__fadeIn'>
            <Text>Select your profile picture:</Text>
            <GenderIconsWrapper>
              {isMan ? (
                <CircleChange>
                  <BtnIcon
                    style={isWoman ? { paddingTop: 8 } : {}}
                    onClick={() => {
                      setGender('male')
                    }}
                    aria-label={getAltAttribute('man')}
                    alt={getAltAttribute('man')}
                  >
                    <img src={Man} aria-hidden />
                  </BtnIcon>
                  <CheckMark>
                    <img src={Mark} aria-hidden />
                  </CheckMark>
                </CircleChange>
              ) : (
                <BtnIcon
                  ref={manIcon}
                  onClick={() => {
                    setGender('male')
                  }}
                  onKeyDown={pressHotkeys}
                  aria-label={getAltAttribute('man')}
                  alt={getAltAttribute('man')}
                >
                  <img src={Man} aria-hidden />
                </BtnIcon>
              )}
              {isWoman ? (
                <CircleChange style={{ marginLeft: 10 }}>
                  <BtnIcon
                    onClick={() => {
                      setGender('female')
                    }}
                    aria-label={getAltAttribute('woman')}
                    alt={getAltAttribute('woman')}
                  >
                    <img src={Woman} aria-hidden />
                  </BtnIcon>
                  <CheckMark>
                    <img src={Mark} aria-hidden />
                  </CheckMark>
                </CircleChange>
              ) : (
                <BtnIcon
                  onClick={() => {
                    setGender('female')
                  }}
                  onKeyDown={pressHotkeys}
                  aria-label={getAltAttribute('woman')}
                  alt={getAltAttribute('woman')}
                >
                  <img src={Woman} aria-hidden />
                </BtnIcon>
              )}
            </GenderIconsWrapper>
          </UsersContainer>
        )}
        {gender && name && (
          <ButtonWrapper>
            <NavButton
              className='animate__animated animate__fadeIn'
              onClick={onReadyBtnClick}
              onKeyDown={({ key }) => key === 'Enter' && onReadyBtnClick()}
              title="I'm ready"
              disabled={isValidateError}
            />
          </ButtonWrapper>
        )}
      </main>
    </Wrapper>
  )
}

export default Registration
