import React from 'react'
import styled from 'styled-components'

const Loader = styled.div`
  color: gray;
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  clip-path: inset(0 1ch 0 0);
  animation: l 2s steps(4) infinite;
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
`

const Container = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
  height: 100%;
`

const LoaderMini = () => {
  return (
    <Container>
      <Loader>Loading...</Loader>
    </Container>
  )
}

export default LoaderMini
