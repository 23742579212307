import React, { useEffect, useRef } from 'react'
import { Steps } from 'intro.js-react'
import { steps } from '../constants/assets/firstSteps.json'
import { useConfigContext } from '../hooks'
import ErrorBoundary from './ErrorBoundary'

const Onboarding = ({ enabled, onLaunch, onFinish }) => {
  const introRef = useRef(steps)
  const isOnExitCalled = useRef(false)

  const {
    customerConfig: { customer },
  } = useConfigContext()
  const classList = ['main-menu-page', 'page-switcher']

  const getUpdatedSteps = (steps) => {
    const customerClass =
      customer === 'tracfone' ? 'onboarding-tf' : 'onboarding-st'
    const updatedSteps = steps.map((step) => ({
      ...step,
      tooltipClass: `${step.tooltipClass} ${customerClass}`,
    }))
    return updatedSteps
  }

  const currentSteps =
    window.innerWidth <= 770
      ? getUpdatedSteps(steps.isMobile)
      : getUpdatedSteps(steps.isDesktop)

  const darkenBackground = (classes) => {
    classes.map((cl) => {
      const el = document.getElementsByClassName(cl)[0]
      el.classList.add('darken-overlay')
    })
  }

  const restoreBackground = (classes) => {
    classes.map((cl) => {
      const el = document.getElementsByClassName(cl)[0]
      if (el.classList.contains('darken-overlay')) {
        el.classList.remove('darken-overlay')
      }
    })
  }

  const onExit = () => {
    isOnExitCalled.current = true
    restoreBackground(classList)
    onFinish()
  }

  const onStart = () => {
    onLaunch()
    darkenBackground(classList)
  }
  const onboardingOptions = {
    disableInteraction: false,
    overlayOpacity: 0.96,
    showBullets: false,
    showStepNumbers: false,
  }

  let prevButton
  let prevArrow

  useEffect(() => {
    prevButton = document.querySelector('.introjs-prevbutton')
    prevArrow = document.querySelector('.arrow1')
  })

  return (
    <Steps
      ref={introRef}
      options={onboardingOptions}
      enabled={enabled}
      steps={currentSteps}
      initialStep={0}
      onStart={onStart}
      onExit={onExit}
      onBeforeExit={() => !isOnExitCalled.current}
      onAfterChange={(i) => {
        if (i !== 2 && i !== 0) {
          setTimeout(() => {
            prevButton.style.display = 'block'
            prevButton.removeAttribute('aria-label')
            prevButton.focus()
          }, 350)
        }
        if (i === 0) {
          setTimeout(() => {
            prevButton.style.display = 'none'
          }, 300)
        }
        if (i === 2) {
          setTimeout(() => {
            prevArrow.focus()
          }, 350)
        }
      }}
    />
  )
}

const WithErrorBoundaryOnboarding = (props) => (
  <ErrorBoundary>
    <Onboarding {...props} />
  </ErrorBoundary>
)

export default WithErrorBoundaryOnboarding
