import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import BlackArrow from '../assets/BlackArrow.svg'
import Checked from '../assets/Checked.svg'
import { pressHotkeys } from '../helpers/utils'

const animationHandler = (type, animate) => {
  let style = ''

  switch (type) {
    case 'fadeIn':
      style += 'opacity: 0;'
      break
    case 'fadeOut':
      style += 'opacity: 1;'
      break
    default:
      style += 'opacity: 1;'
      break
  }

  if (animate) {
    style += `animation-name: ${type};`
  }

  return css`
    ${style}
  `
}

const Wrapper = styled(NavLink)`
  background-color: #ffffff;
  padding: 23px;
  margin: 15px;
  overflow: hidden;
  cursor: pointer;
  border:  ${({ completed, theme }) => (completed ? `2px solid ${theme.retailer.primaryColor}` : `2px solid ${theme.retailer.icon.color}`)};
  left: 0%;
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  border-radius: 90px;
  align-items: center;
  position: relative;
  z-index: 2;
  outline-offset: 6px;
  @keyframes progress {
    0% {
      right: 110%;
    }
    25% {
      right: 75%;
    }
    50% {
      right: 50%;
    }
    75% {
      right: 25%;
    }
    100% {
      right: -10%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: -10%;
    top: 0;
    height: 100%;
    width: 130%;
    background-color: inherit;
    transform: skewX(-20deg);
    background: ${({ theme }) => theme.retailer.primaryColor};
    right: 110%;
    z-index: -1;

    ${({ completed }) => completed
      && css`
        animation-name: progress;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
      `}
  }
`

const TextWrapper = styled.div`
  min-width: 85px;
  text-align: start;
  text-decoration: none !important;

  h5 {
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 3px 6px 0;
    color: ${({ completed, theme }) => {
    if (completed && !theme.retailer.lightTheme) return '#0c0c0d'
    if (completed && theme.retailer.lightTheme) return '#fff'
    return 'black'
  }};
  }

  span {
      color: ${({ completed, theme }) => {
    if (completed && !theme.retailer.lightTheme) return '#0c0c0d'
    if (completed && theme.retailer.lightTheme) return '#fff'
    return 'black'
  }};
    font-size: 14px;
    margin: 4px 0px 0px 0px;
  }
`

const IconWrapper = styled.div`
  position: relative;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Icon = styled.img`
  transition: opacity 1.5s;
  outline: 2px solid white;
  border-radius: 80px;
  position: absolute;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${(props) => animationHandler(props.type, props.animate)}
`

const TutorialButton = ({
  wrpStyle, completed, title, text, onClick, to
}) => (
  <Wrapper
    tabIndex={0}
    to={to}
    onKeyDown={pressHotkeys}
    onClick={onClick}
    completed={completed}
    style={wrpStyle}
    aria-label={completed ? `${title}, completed` : `${title}, ${text}`}
  >
    <TextWrapper aria-hidden="true" completed={completed}>
      <h5>{title}</h5>
      <span>{text}</span>
    </TextWrapper>
    <IconWrapper aria-hidden="true">
      <Icon
        src={Checked}
        height="auto"
        width={24}
        type="fadeIn"
        animate={completed}
        alt=""
        aria-hidden
      />
      <Icon
        src={BlackArrow}
        height="auto"
        width={24}
        type="fadeOut"
        animate={completed}
        alt=""
        aria-hidden
      />
    </IconWrapper>
  </Wrapper>
)

export default TutorialButton
