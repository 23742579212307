import React, { useEffect, useContext, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { UserContext } from '../UserContext'
import Header from './Header'
import { postAnalytics } from '../services/analytics'

import WhiteArrow from '../assets/WhiteArrow.svg'
import BackgroundCircle from '../assets/BackgroundCircle.svg'
import Achieve from './Achieve'

import ExploringBadge from '../assets/ExploringBadge.svg'
import SetupBadge from '../assets/SetupBadge.svg'
import TechBadge from '../assets/TechBadge.svg'
import ShieldedBadge from '../assets/ShieldedBadge.svg'
import { useConfigContext } from '../hooks'
import { getParam } from '../helpers/parameters'

const Wrapper = styled.div`
  min-height: 100%;
  background: ${({ theme }) => `${theme.retailer.backgroundGradient}`} no-repeat,
    url(/images/appBackground.svg);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply, normal;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  overflow-y: auto;
  flex-direction: column;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Body = styled.body`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const NavLink = styled(RouterNavLink)`
  display: flex;
  align-items: center;
  background: ${({ theme }) =>
    theme.retailer.default ? theme.retailer.secondaryColor : '#fff'};

  padding: 24px 48px;

  border: none;

  z-index: inherit;
  color: ${({ theme }) => (theme.retailer.default ? '#fff' : '#000')};

  letter-spacing: 0px;
  text-transform: uppercase;

  margin-top:20%;

  @media (min-height: 340px) and (max-height: 720px) {
    margin-top:5%;
  }
  
  span {
    font-size: 16px;
    font-weight: 700;
  }

  img {
    width: 30px;
    height: 22px;
    filter: ${({ theme }) => (theme.retailer.default ? 'none' : 'invert(1)')};
  }

  ${(props) => {
    if (!props.flip) {
      return css`
        border-radius: 50px;
      `
    }

    return css`
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      border-right: 0;

      img {
        transform: scaleX(-1);
      }
    `
  }}
`

const Title = styled.h1`
  margin: 0px;
  p {
    margin: 5px;
    text-align: center;
    font: normal bold 48px/36px Josefin Sans;
  }
  text-align: center;
  font: normal bold 48px/56px Josefin Sans;
  letter-spacing: 0px;
  color: ${({ color, theme }) =>
    theme.retailer.default ? color : theme.retailer.primaryColor};
`

const Subtitle = styled.h2`
  margin: 0px;
  text-align: center;
  font: normal normal bold 24px/32px Josefin Sans;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;

  p {
    margin: 0px;
  }

  b {
    text-align: center;
    font: normal normal bold 24px/32px Josefin Sans;
    letter-spacing: 0px;
    color: ${({ color, theme }) =>
      theme.retailer.default ? color : theme.retailer.primaryColor};
    text-transform: uppercase;
  }
`

const AwardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${BackgroundCircle});
  background-repeat: no-repeat;
  background-position: center;
  padding: 185px 210px;
  position: relative;

  @media (min-width: 340px) and (max-width: 475px) {
    background-size: 380px;
    padding: 135px 165px;
  }

  @media (min-height: 340px) and (max-height: 720px) {
    background-size: 380px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.theme.media.iphone7(css`
      bottom: 2%;
    `)}
  margin-bottom: 5%;
`

const iconSrc = [
  {
    src: ExploringBadge,
    name: 'Exploring',
    color: '#e340ff',
  },
  {
    src: SetupBadge,
    name: 'Setting up your phone',
    color: '#ffffff'
  },
  {
    src: TechBadge,
    name: 'Using your phone',
    color: '#55C5FF',
  },
  {
    src: ShieldedBadge,
    name: 'Shielded',
    color: '#9F4BFF',
  },
]

const BadgeAward = ({ onDismiss, badgeId, iconPath, name }) => {
  const assetsUrl = getParam('assetsUrl')
  const user = useContext(UserContext)
  const { customerConfig: { customer } } = useConfigContext()
  const [icon, setIcon] = useState('')

  const AwardWrapperRef = useRef(null)

  useEffect(() => {
    postAnalytics({
      category: 'badgeearned',
      data: {
        badge_id: badgeId,
        badge_name: name,
        customer,
      },
    })
    returnIcon(name)
  }, [badgeId, name])

  const returnIcon = (name) => {
    iconSrc.map((item) => {
      if (item.name.toLowerCase() === name.toLowerCase()) {
        setIcon(item)
      }
    })
  }

  return (
    <Wrapper>
      <Header pageName={`Master of ${name}`} inverted={true} wrpStyle={{ background: 'transparent' }} />
        <Body role='main'>
          <Title color={icon.color || '#9F4BFF'} aria-label={`WAY TO GO! ${user.userName}`}>
            <p>WAY TO GO!</p>
            {user.userName}
          </Title>
          <AwardWrapper ref={AwardWrapperRef}>
            <Achieve color={'#F59805'} icon={icon.src || `${assetsUrl}${iconPath}`} />
          </AwardWrapper>
          <Subtitle color={icon.color} aria-label={`Master of ${name} Achieved!`}>
            <p aria-hidden>
              Master of
              <br />
              <b>{name}</b>
              <br />
              Achieved!
            </p>
          </Subtitle>
          <ButtonWrapper>
            <NavLink
              to="/main-menu"
              onClick={onDismiss}
            >
                <span>Main Menu</span>
                <img src={WhiteArrow} height="auto" width={38} alt="" aria-hidden />
            </NavLink>
          </ButtonWrapper>
        </Body>
    </Wrapper>
  )
}

export default BadgeAward
