import React, {
  createContext, useCallback, useEffect, useState, ReactNode,
} from 'react'

import { useLocalStorageState } from './hooks'
import { fetchGetFullCustomer } from './services'

const User = {
  userName: null,
  userGender: null,
  id: null,
  setUser: () => {},
  clearUser: () => {},
  points: 0,
}

export const UserContext = createContext(User)
interface IUserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: IUserContextProviderProps) => {
  const [user, setUser] = useLocalStorageState('user')
  const [points, setPoints] = useState<number>(0)

  const fetchCustomer = useCallback(async () => {
    if (user?.id) {
      const customer = await fetchGetFullCustomer(user.id)

      setPoints(customer.points)
    }
  }, [user])

  useEffect(() => {
    fetchCustomer()
  }, [])

  const clearUser = () => setUser({})

  const value = {
    userName: user?.name,
    userGender: user?.gender,
    id: user?.id,
    points,
    setUser,
    clearUser,
  }

  return (
    <UserContext.Provider value={value} >
      {children}
    </UserContext.Provider>
  )
}
