import React, { useState, useContext, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import NavButton from '../components/NavButton'
import HamburgerMenu from '../components/HamburgerMenu'
import Header from '../components/Header'
import Greetings from '../components/Greetings'
import { UserContext } from '../UserContext'
import { useConfigContext } from '../hooks'
import DeviceListModal from '../components/DeviceListModal'
import theme from '../constants/theme'
import { hardcodedDataOnPage } from '../constants/assets/data'

const Wrapper = styled.div`
  background: ${({ theme }) => `${theme.retailer.backgroundGradient}`} no-repeat,
    url(/images/appBackground.svg);
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;
  overflow: auto;
  min-height: 100%;
`

const TextWrapper = styled.div`
  width: 80%;
  margin: 20% auto 0;
  margin-top: 16%;
  text-align: center;
  padding-bottom: 21px;
  font-weight: 700;

  ${(props) =>
    props.theme.media.iphone7(css`
      padding-top: 0;
    `)}

  .home-heading {
    margin: 15px 0px 0px 0px;
    color: ${({ theme }) =>
      theme.retailer.default ? '#57DF57' : theme.retailer.primaryColor};
    font-size: 3rem;
  }
  span {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    color: white;
  }
`

const ButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const history = useHistory()
  const user = useContext(UserContext)
  const { customerConfig } = useConfigContext()
  const [isDevicesModal, setIsDevicesModal] = useState(false)
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.home,
    []
  )

  const path = window.location.search

  useEffect(() => {
    if (user.name && !path.includes('testing')) {
      history.push('/main-menu')
    }
  }, [history, user])

  if (menuOpen) {
    return <HamburgerMenu />
  }

  return (
    <Wrapper>
      <Header
        onMenuClick={() => {
          setMenuOpen(!menuOpen)
        }}
        notShow={true}
        isLightTheme={theme.retailer.default}
        logoAlt={`Go to ${customerConfig.customerModalName} website`}
        pageName={'Welcome Center'}
      />
      {isDevicesModal ? (
        <DeviceListModal
          isDevicesModal={isDevicesModal}
          setIsDevicesModal={setIsDevicesModal}
        />
      ) : null}
      <TextWrapper as='main'>
        <h1 className="home-heading" aria-label='Say hello to your new device'>
          <span aria-hidden>SAY</span>{' '}
          <Greetings
            heading="Hello"
            text={
              path.includes('testing')
                ? 'To your new Testing environment'
                : 'To your new Device'
            }
          />
        </h1>
        <h2>{pagesData.description}</h2>
      </TextWrapper>
      <ButtonWrapper>
        <NavButton
          onClick={() => {
            path.includes('testing')
              ? setIsDevicesModal((prev) => !prev)
              : history.replace('/registration')
          }}
          title="Let's get started"
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default Home
