export const extraBadges = [
  {
    isDone: false,
    createdTime: '2021-03-25T09:13:20.000Z',
    id: '2f2db7e2-1121-48da-920e-b17cc34dcc4b',
    description: 'Exploring',
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
    status: 'READY',
    fileId: '25f95e40-3c33-45f0-994b-b3385646f49e',
    isExtraBadges: true,
    type: 'badges',
    slug: 'exploring',
    name: 'Exploring',
    description:
      'Discover mystic relics and travel around all Straight Talk World. The stakes and rewards are high.',
    link: '/discover',
    icon_name: 'ExploringBadge.svg',
    fileId: '25f95e40-3c33-45f0-994b-b3385646f49e',
    tasks: [
      {
        title: 'Tracking minutes?',
        isDone: false,
      },
      {
        title: 'Continuous service',
        isDone: false,
      },
      {
        title: 'Play to win & save',
        isDone: false,
      },
      {
        title: 'Call abroad!',
        isDone: false,
      },
      {
        title: 'Missing something?',
        isDone: false,
      },
    ]
  },
  {
    isDone: true,
    createdTime: '2020-12-24T18:13:47.000Z',
    id: '43d2f029-bab6-470c-8c44-17a8f7368102',
    description: 'Welcome to the family',
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
    status: 'READY',
    fileId: 'd8e66f86-6107-4401-b89d-ed98be9e07fc',
    isExtraBadges: true,
    type: 'welcome',
    slug: 'welcome-to-the-family',
    name: 'Welcome to the family',
    description:
      'We are happy to have you onboard and be part of our family. For this and many years more.',
    link: '',
    icon_name: 'WelcomeBadge.svg',
    fileId: 'd8e66f86-6107-4401-b89d-ed98be9e07fc',
  },
  {
    isDone: false,
    createdTime: '2021-01-25T13:48:45.000Z',
    id: '74227947-4a2a-4e49-9d0a-f7984e41b8e0',
    description: 'Shielded',
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
    status: 'READY',
    fileId: '41e9216c-d89a-49cb-84c5-9214adc1b715',
    isExtraBadges: true,
    type: 'badges',
    slug: 'shielded',
    name: 'Shielded',
    description:
      'Safety and protection are key. Discover how to protect your phone today against multiple risks.',
    link: '/protect',
    icon_name: 'ShieldedBadge.svg',
    fileId: '41e9216c-d89a-49cb-84c5-9214adc1b715',
  },
]
