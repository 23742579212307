import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import { BadgesCtx } from '../BadgesCtx'
import Tutorials from './MainMenu/components/Tutorials'
import TutorialSteps from './MainMenu/components/TutorialSteps'
import { useLocalStorageState } from '../hooks'
import TutorialComplete from './TutorialComplete'
import BadgeAward from '../components/BadgeAward'
import BreadCrumbs from '../components/BreadCrumbs'
import PageSwitcher from '../components/PageSwitcher'

const Wrapper = styled.section`
  background: #ffffff;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`

export const TaskSteps = () => {
  const { id } = useParams()
  const history = useHistory()
  const badgeCtx = useContext(BadgesCtx)
  const [tutorialCompleted, setTutorialCompleted] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.screen.width)

  const getScreenWidth = (e) => setScreenWidth(e.target.innerWidth)
  const isMobile = screenWidth < 767

  const goBack = () => {
    history.push(`/badges/${badgeCtx.currentCategory.id}`)
  }
  const historyPages = useMemo(
    () => [
      { title: 'Main Menu', link: '/main-menu' },
      {
        title: badgeCtx.currentCategory.name ? badgeCtx.currentCategory.name : '',
        link: `/badges/${badgeCtx.currentCategory.id}`,
      },
      { title: badgeCtx.currentTask?.tutorial?.title },
    ],
    [badgeCtx, isMobile],
  )

  const historyPagesMobile = useMemo(
    () => [
      { title: 'Main Menu', link: '/main-menu' },
      {
        title: badgeCtx.currentCategory.name ? badgeCtx.currentCategory.name : '',
        link: `/badges/${badgeCtx.currentCategory.id}`,
      },
    ],
    [badgeCtx],
  )

  const completeSteps = () => {
    setTutorialCompleted(true)
  }

  useEffect(() => {
    window.addEventListener('resize', getScreenWidth)
    return () => {
      window.removeEventListener('resize', getScreenWidth)
    }
  }, [])

  if (tutorialCompleted) {
    return <TutorialComplete onLinkClick={`/badges/${badgeCtx.currentCategory.id}`} />
  }

  return (
    <Wrapper aria-label="There is new information on the screen">
      <Header
        inverted={true}
        pageName={badgeCtx?.currentCategory?.name || 'Tutorial'}
      />
      {!isMobile && (
        <PageSwitcher
          navLeft="/explore-more"
          navRight="/learning-curve"
          pageTitle="My badges"
          pageNumber={1}
        />
      )}
      <BreadCrumbs historyPages={historyPages} isMobile={isMobile} />
      <main>
        <TutorialSteps
          id={id}
          completedStep={badgeCtx.currentTask.isDone}
          badgeId={badgeCtx.currentCategory.id}
          completeSteps={completeSteps}
          closeSteps={goBack}
          isMobile={isMobile}
        />
      </main>
    </Wrapper>
  )
}

export const BadgeTasks = () => {
  const history = useHistory()
  const { badgeId } = useParams()
  const {
    currentCategory,
    setCurrentCategoryId,
  } = useContext(BadgesCtx)
  const [progress, setProgress] = useLocalStorageState('progress')
  const [isBadgeAwardShown, setIsBadgeAwardShown] = useLocalStorageState(
    `badge-award-${badgeId}`,
    false
  )

  const onDismiss = () => {
    setIsBadgeAwardShown(true)
  }

  const tasks = useMemo(
    () => progress.find((item) => item.id === badgeId)?.wcTutorials || [],
    [progress, badgeId],
  )

  useEffect(() => {
    setCurrentCategoryId(badgeId)
  }, [badgeId, setCurrentCategoryId])

  const allDone = (tsks) =>
    tsks.filter((task) => task.isDone).length === tsks.length

  if (
    tasks &&
    tasks.length !== 0 &&
    allDone(tasks) &&
    !isBadgeAwardShown &&
    badgeId === currentCategory.id
  ) {
    return <BadgeAward 
      badgeId={currentCategory.id}
      icon={currentCategory.iconCategory}
      name={currentCategory.name}
      onDismiss={onDismiss} />
  }

  return (
    <Wrapper>
      <Header pageName={currentCategory.name} inverted={true} />
      {!tasks ? null : (
        <Tutorials
          closeTutorial={() => {
            history.push('/main-menu')
          }}
          selectedCategory={currentCategory.name}
          badge={badgeId}
          tutorials={tasks}
        />
      )}
    </Wrapper>
  )
}
