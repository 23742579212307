import React from 'react'
import styled, { css } from 'styled-components'
import WhiteArrow from '../assets/WhiteArrow.svg'
import { NavLink } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled(({ href, ...props }) => {
  if (!href) return <button {...props} />;

  const isLocalLink = !href.includes('http')

  if (isLocalLink) return <NavLink to={href} {...props} />;
  if (!isLocalLink && href) return <a href={href} {...props} />;
  return <div {...props} />;
})`
  background-color: ${({ disabled, theme }) => disabled ? 'gray' : theme.retailer.secondaryColor};
  border-radius: 64px;
  padding: 24px 48px;
  border: none;
  cursor: pointer;
  margin: 30px 30px 20px 30px;
  display: flex;
  max-height: 64px;
  min-height: 72px;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  :focus, :focus-visible {
    outline-offset: 6px;
    outline-color: black;
  }

  ${({ outline }) =>
    outline
      ? css`
          background: transparent;
          border: 3px solid #fff;
          border-left: 0;
        `
      : ''}

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
      margin-top: 0;
    }
  }
`

const Title = styled.span`
  color: ${({ disabled, theme }) =>
    disabled ? '#232323' : theme.retailer.default ? '#fff' : '#000'};
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 30px;
`

const Icon = styled.img`
  bottom: 17px;
  right: 22px;
  width: 25px;
  height: 25px;
  filter: ${({ theme }) => (theme.retailer.default ? 'none' : 'invert(1)')};
`

const NavButton = ({ wrpStyle, disabled, title, ...props }) => (
  <Container>
    <Wrapper
      {...props}
      disabled={disabled}
      style={wrpStyle}
      aria-label={title}
      tabIndex={0}
    >
      <Title disabled={disabled}>{title}</Title>
      <Icon
        tabIndex='-1'
        aria-hidden='true'
        src={WhiteArrow}
        height='auto'
        width={38}
      />
    </Wrapper>
  </Container>
)

export default NavButton
