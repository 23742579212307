import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Slider, { SliderTooltip } from 'rc-slider'
import { Link } from 'react-router-dom'
import 'rc-slider/assets/index.css'
import { createPortal } from 'react-dom'
import Lock from '../assets/Lock.svg'
import Checked from '../assets/Checked.svg'

import Overlay from './Overlay'
import OrangeBadge from './OrangeBadge'
import { pressHotkeys } from '../helpers/utils'
import useImage from '../hooks/useImage'

const Wrapper = styled.div`
  max-width: 500px;
  padding: 20px;
  width: 100%;

  @media (min-width: 360px) and (max-width: 1024px) {
    width: 80%;
    margin: 0 auto 10px auto;
  }

  .rc-slider-handle {
    width: 34px;
    height: 34px;
    ${({ $inProgress, $full }) => {
      if ($inProgress) {
        return css`
          display: none;
        `
      }
      return css`
        background: url(${$full ? Checked : Lock}) no-repeat;
        right: 0 !important;
        left: inherit !important;
        transform: none !important;
      `
    }}
    margin-top: 1px;
    border: none;
  }

  .rc-slider-track {
    height: 35px;
    background: ${({ $full, theme }) =>
      $full
        ? theme.retailer.default && '#57DF57'
        : theme.retailer.backgroundGradientSlider};
    border-radius: 50px;
    transition: all 0.2s linear;
  }

  .rc-slider-rail {
    z-index: 1;
    height: 35px;

    border: ${({ $full }) => !$full && '1px solid #000'};
    border-radius: 40px;
    background: transparent;
  }

  .rc-slider-disabled {
    background: transparent;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 18px;
  color: #221f20;

  text-align: initial;
  margin: 10px 0px 7px 0px;
`

const StyledBtn = styled.button`
  text-align: center;
  font: 12px "Open Sans", sans-serif !important;
  color: #221f20;
  cursor: pointer;
  border: none;
  background: transparent;
`

const Stat = styled.div`
  text-align: left;
  font: normal normal 400 12px/14px 'Open Sans', sans-serif;
  color: #373737;
  margin-top: 16px;
`

const Value = styled.span`
  font-size: 14px;
  position: relative;
  top: 0px;
  color: #313334;
`

const { Handle } = Slider

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

const ProgressSlider = ({
  title,
  previousLevelTitle,
  min,
  max,
  value,
  placeholder,
  badgeText,
  icon,
}) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const $full = value >= max
  const $inProgress = min < value && value < max
  const { image } = useImage(icon)

  const overlayWrapperRef = useRef(null)
  const calcPercentage = (currentValue) =>
    (((currentValue - min) * 100) / (max - min)).toFixed(0)
  const getAriaLabel = (currValue, prevLevelTitle) => {
    return !previousLevelTitle || calcPercentage(currValue) > 0
      ? null
      : `${title}. 0 percent. Locked. To unlock this level complete the ${prevLevelTitle} level first.`
  }

  useEffect(() => {
    if (showOverlay) {
      document.querySelector('.App').setAttribute('aria-hidden', true)
      overlayWrapperRef.current.focus()
    } else document.querySelector('.App').setAttribute('aria-hidden', false)
  }, [showOverlay])

  return (
    <>
      <Wrapper
        id="main-content"
        className="slider-wrapper"
        disabled
        $full={$full}
        $inProgress={$inProgress}
      >
        <Header>
          <Title aria-hidden={showOverlay} $full={$full}>
            {title}
          </Title>
          <StyledBtn
            aria-label={`Learn more about the ${title} section`}
            onClick={() => setShowOverlay(true)}
            onKeyDown={pressHotkeys}
          >
            Learn More &gt; &gt;
          </StyledBtn>
        </Header>
        <Slider
          aria-label={(currentValue) =>
            getAriaLabel(currentValue, previousLevelTitle)
          }
          min={min}
          max={max}
          defaultValue={value}
          value={value}
          handle={handle}
          disabled
        />
        <Value
          role="dialog"
          aria-live="off"
          aria-hidden={showOverlay}
          $full={$full}
          aria-label={
            $inProgress
              ? `${value} Minutes. ${calcPercentage(
                  value
                )}% completed to be an ${title}`
              : `0% of ${max} Minutes`
          }
        >
          {$inProgress ? `${value} Minutes` : placeholder}
        </Value>
        <Stat aria-hidden={showOverlay}>0.15% of customers</Stat>
      </Wrapper>
      {showOverlay && createPortal((
        <Overlay
          onClick={() => {
            setShowOverlay(false)
          }}
        >
          <OrangeBadge
            title={title}
            btnTitle="Access section"
            onClose={() => {
              setShowOverlay(false)
            }}
            text={badgeText}
            icon={image}
            wrapperRef={overlayWrapperRef}
          />
        </Overlay>
      ), document.body)}
    </>
  )
}

export default ProgressSlider
