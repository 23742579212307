import { getParam } from '../helpers/parameters'
import { attachBearerToken } from './attach-bearer-token'
import { fetchGetJson } from './fetch-get-json'

export const fetchContentApi = async (
  relativePath,
  config = { method: 'GET' },
  body,
) => {
  const [kmsApiBase, fetchOptions] = await Promise.all([
    getParam('kmsApiBase'),
    attachBearerToken(attachBodyToConfig(config, body)),
  ])

  const url = `${kmsApiBase}${relativePath}`
  try {
    const response = await fetchGetJson(url, fetchOptions)
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

const attachBodyToConfig = (config, body) => {
  if (typeof body !== 'string' || !isJson(body)) {
    return { ...config, body: JSON.stringify(body) }
  }
  return { ...config, body }
}
const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
