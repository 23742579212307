export const fetchGetJson = async (url, config) => {
  const res = await fetch(url, config)
  const data = await res.json()

  if (data.error || !res.ok) {
    throw new Error(data?.message || res.statusText)
  }

  return data
}
