import React from 'react'
import styled from 'styled-components'
import { COLOR } from '../constants/color'

const Wrapper = styled.div`
  margin: 0 auto;
`

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: ${COLOR.gray};
  border: 1px solid ${COLOR.grayLight};
  width: 280px;
  height: 45px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 18px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${COLOR.grayMid};
    font-family: "Josefin Sans", sans-serif;
  }
`

const Input = ({
  wrpStyle, inpStyle, onClickSearch, onClickMic, ...props
}) => (
  <Wrapper style={wrpStyle}>
    <StyledInput {...props} style={inpStyle} />
  </Wrapper>
)

export default Input
