import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react'
import Parser from 'html-react-parser'
import styled from 'styled-components'
import {
  fetchTutorialStepsApi,
} from '../../../services'
import InfoBox from '../../../components/InfoBox'
import { BadgesCtx } from '../../../BadgesCtx'
import { postAnalytics } from '../../../services/analytics'
import LoaderMini from '../../../components/LoaderMini'
import { getParam } from '../../../helpers/parameters'
import { useConfigContext } from '../../../hooks'
import TutorialMobileView from '../../../components/TutorialMobileView'
import TutorialDesktopView from '../../../components/TutorialDesktopView'

const tooltipOptions = {
  textColor: '#221F20',
  border: true,
  borderColor: '#012979',
  backgroundColor: 'white',
  event: 'click',
  multiline: true,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 10px;
  align-items: center;
  @media (max-width: 767px) {
    padding-bottom: 137px;
    padding-top: 0;
  }
`

const parseHTML = (text, options) => Parser(text, {
  replace: (domNode) => {
    if (domNode.attribs && domNode.attribs.class === 'explanation') {
      return (
        <InfoBox
          node={domNode}
          tooltipOptions={options}
          icon="categories/information.svg"
        />
      )
    }
    return null
  },
})

const extractContent = (html) => new DOMParser().parseFromString(html, 'text/html').documentElement.textContent

const TutorialSteps = ({
  id,
  completedStep,
  completeSteps,
  badgeId,
  closeSteps,
  isMobile,
}) => {
  const { customerConfig: { customer } } = useConfigContext()
  const assetsUrl = getParam('assetsUrl')
  const { updateProgress } = useContext(BadgesCtx)
  const [stepNumber, setStepNumber] = useState(1)
  const [displayedStepNumber, setDisplayedStepNumber] = useState(1)
  const [steps, setSteps] = useState([])
  const [title, setTitle] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const tutorialStartTime = useRef(new Date())
  const inputRef = useRef(null)

  const currentStep = steps[stepNumber - 1]

  const trackProgress = useCallback(
    (type, stepNum, titleOverride) => postAnalytics({
      category: 'tutorials',
      data: {
        type,
        title: title || titleOverride,
        step: stepNum,
        tutorial_id: id,
        customer,
      },
    }),
    [id, title],
  )

  const getSteps = useCallback(async () => {
    const {
      steps: stepsArray,
      title: name,
      product: { faceplate },
    } = await fetchTutorialStepsApi(id)
    const ActionTypes = (type) => {
      switch (type) {
        case 'swipe_up':
          return 'UP'
        case 'swipe_down':
          return 'DOWN'
        case 'swipe_left':
          return 'LEFT'
        case 'swipe_right':
          return 'RIGHT'
        case 'automate':
          return 'NONE'
        case 'click':
          return 'NULL'
        default:
          return 'NONE'
      }
    }
    const filteredValue = stepsArray
      .map((item) => ({
        ...item,
        buttonScreenImages: [
          {
            buttonXSize: 32,
            buttonXUpperLeft: item?.action?.x,
            buttonYSize: 32,
            buttonYUpperLeft: item?.action?.y,
          },
        ],
        packageFile: {
          location: '',
        },
        phoneScreenImage: {
          faceplate_behind: Boolean(faceplate.is_behind),
          imageLocation: `${assetsUrl}${
            faceplate[
              item.orientation === 'portrait'
                ? 'portrait_image'
                : 'landscape_image'
            ]
          }`,
          screenXSize:
            faceplate[
              item.orientation === 'portrait'
                ? 'portrait_width'
                : 'landscape_width'
            ],
          screenXUpperLeft:
            faceplate[
              item.orientation === 'portrait' ? 'portrait_x' : 'landscape_x'
            ],
          screenYSize:
            faceplate[
              item.orientation === 'portrait'
                ? 'portrait_height'
                : 'landscape_height'
            ],
          screenYUpperLeft:
            faceplate[
              item.orientation === 'portrait' ? 'portrait_y' : 'landscape_y'
            ],
        },
        tutorial: {
          name,
        },
        tutorialstep: {
          animationJson: [
            {
              delay: String(item.delay),
              image: `${assetsUrl}${item.file_path}`,
            },
            {
              delay: String(item.delay),
              image: `${assetsUrl}${item.file_path}`,
            },
          ],
          imageLocation: `${assetsUrl}${item.file_path}`,
          lcdButton: Boolean(item.on_screen),
          orientation: item.orientation,
          outputOrder: item.order,
          step: item.text,
          swipeOrAction: ActionTypes(item?.action?.type),
        },
      }))
    setSteps(filteredValue)
    setTitle(name)
    trackProgress('start', displayedStepNumber, name)
    setIsFetching(false)
  }, [id, displayedStepNumber, trackProgress])

  const changeActiveStep = (i) => {
    const currentNumber = steps.findIndex((item) => (i === item.order))
    setStepNumber(currentNumber + 1)
    setDisplayedStepNumber(i)
    trackProgress('goto-step', i)
  }

  const nextSubstep = () => {
    if (stepNumber === steps.length) navButtonClick()
    if (displayedStepNumber < steps.length) {
      if (steps[stepNumber].tutorialstep.step) {
        changeActiveStep(displayedStepNumber + 1)
        return
      }
      setStepNumber(stepNumber + 1)
    }
  }

  const prevSubstep = () => {
    if (stepNumber === 1) return
    const currentIndex = stepNumber - 1
    setStepNumber(currentIndex)
    const isOrderDifferent =
      steps[currentIndex]?.order !== steps[currentIndex - 1]?.order

    if (isOrderDifferent) {
      setDisplayedStepNumber(displayedStepNumber - 1)
      trackProgress('goto-step', displayedStepNumber - 1)
    }
  }

  useEffect(() => {
    if (isFetching) {
      getSteps()
    }
  }, [getSteps, isFetching])

  const hardcodedData = {
    1: { left: '', top: '' },
    2: { left: '112', top: '238' },
    3: { left: '', top: '' },
    4: { left: '318', top: '208' },
    5: { left: '', top: '' },
    6: { left: '325', top: '213' },
    7: { left: '', top: '' },
  }

  const navButtonClick = async () => {
    setIsSubmitting(true)
    if (!completedStep) {
      trackProgress('completed', displayedStepNumber)
      updateProgress(badgeId)

      completeSteps()
    } else {
      trackProgress('repeat-completed', displayedStepNumber)
      closeSteps()
    }
    postAnalytics({
      category: 'tutorialtime',
      data: {
        time_spent: new Date().valueOf() - tutorialStartTime.current.valueOf(),
        customer,
      },

    })
  }

  let liIndex = 0
  const moveOnArrow = (event) => {
    const { key } = event

    if (event.target.localName === 'li' && (key === 'ArrowUp' || key === 'ArrowDown')) event.preventDefault()

    if (key === 'ArrowRight') {
      if (stepNumber === steps.length) navButtonClick()
      if (displayedStepNumber < steps.length) {
        changeActiveStep(displayedStepNumber + 1)
      }
    }
    if (key === 'ArrowLeft') {
      if (displayedStepNumber !== 1) {
        changeActiveStep(displayedStepNumber - 1)
      }
    }

    const liCollection = document.querySelectorAll('li')
    if (liCollection.length > 0) {
      if (key === 'ArrowUp' && liIndex > 0) {
        liIndex -= 1
        liCollection[liIndex].focus()
      }
      if (key === 'ArrowDown' && liIndex < liCollection.length - 1) {
        liIndex += 1
        liCollection[liIndex].focus()
      }
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    const listLi = document.querySelectorAll('li')
    if (listLi.length > 0) {
      listLi.forEach((item) => item.setAttribute('tabIndex', 0))
    }
    document.addEventListener('keydown', moveOnArrow)
    return () => {
      document.removeEventListener('keydown', moveOnArrow)
    }
  }, [displayedStepNumber])

  const filteredSteps = steps.filter(
    (step) => step.text !== '' && !step.is_substep,
  )

  if (isFetching) return <Wrapper><LoaderMini /></Wrapper>

  return (
    <Wrapper>
      {isMobile ? 
        <TutorialMobileView
          nextSubstep={nextSubstep}
          prevSubstep={prevSubstep}
          tutorialTitle={
            !isFetching && steps[displayedStepNumber - 1]?.tutorial?.name
          }
          filteredSteps={filteredSteps}
          displayedStepNumber={displayedStepNumber}
          inputRef={inputRef}
          isFetching={isFetching}
          parseHTML={parseHTML}
          extractContent={extractContent}
          tooltipOptions={tooltipOptions}
          changeActiveStep={changeActiveStep}
          currentStep={currentStep}
          steps={steps}
        /> :
        <TutorialDesktopView
          displayedStepNumber={displayedStepNumber}
          nextSubstep={nextSubstep}
          currentStep={currentStep}
          steps={steps}
          isFetching={isFetching}
          filteredSteps={filteredSteps}
          changeActiveStep={changeActiveStep}
          inputRef={inputRef}
          navButtonClick={navButtonClick} 
          isSubmitting={isSubmitting}
          completedStep={completedStep}
          stepNumber={stepNumber}
          extractContent={extractContent}
          parseHTML={parseHTML}
          tooltipOptions={tooltipOptions}
        />
      }
    </Wrapper>
  )
}

export default TutorialSteps
