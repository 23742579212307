import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import Header from '../components/Header'
import Loader from '../components/Loader'

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  background: url('/images/Home.png') no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
`

const SrOnly = styled.p`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
`

const Loading = () => {
  const history = useHistory()
  const loadingRef = useRef()

  const onComplete = () => {
    history.push('/welcome')
  }

  useEffect(() => {
    loadingRef.current.focus()
  }, [])

  return (
    <Wrapper>
      <SrOnly role="alert" ref={loadingRef} tabIndex="-1">
        0%, 100%
      </SrOnly>
      <Header
        inverted={true}
        wrpStyle={{ background: 'transparent' }}
        pageName={'Loading'}
      />
      <Loader onComplete={onComplete} />
    </Wrapper>
  )
}

export default Loading
