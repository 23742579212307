import React from 'react'
import styled from 'styled-components'
import CurvedLine from './CurvedLine'

const Wrapper = styled.div`
  text-transform: uppercase;
  color: ${({ inverted, theme }) =>
    inverted
      ? '#fff'
      : theme.retailer.default
      ? '#57DF57'
      : `${theme.retailer.primaryColor}`};

  .heading {
    margin-top: -8px;
    margin-bottom: 16px;
    font-size: 64px;
    display: block;
  }

  .secondary-text {
    font-size: 32px;
    line-height: 45px;
    display: block;
    margin-bottom: -6px;

    @media (min-height: 568px) and (max-height: 640px) {
      font-size: 18px;
    }
  }
`

const Greetings = ({
  heading,
  text,
  headingStyle,
  textStyle,
  inverted,
  ...props
}) => (
  <Wrapper inverted={inverted} {...props}>
    <CurvedLine />
    <span className="heading" style={headingStyle}>
      {heading}
    </span>
    <span className="secondary-text" style={textStyle}>
      {text}
    </span>
    <CurvedLine inverded={true} />
  </Wrapper>
)

export default Greetings
