import { addApiKey } from './add-api-key'

const fetchHelper = async (method, url, apiKey, body) => {
  const response = await fetch(url, {
    method,
    ...addApiKey({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    }),
    body,
  })

  const json = await response.json()
  const error = { ...json, status: response.status }

  if (!response.ok) {
    throw error
  }

  return json
}

export const fetchPatch = (url, apiKey, body) => fetchHelper('PATCH', url, apiKey, body)

export const fetchPost = (url, apiKey, body) => fetchHelper('POST', url, apiKey, body)

export const fetchPut = (url, apiKey, body) => fetchHelper('PUT', url, apiKey, body)

export const fetchDelete = (url, apiKey, body) => fetchHelper('DELETE', url, apiKey, body)
