import React, { createContext, useContext } from 'react'
import { OptionsCtx } from './OptionsCtx'

export const ContentCtx = createContext({
  device: undefined,
  tutorials: [],
  tutorialCategories: []
})

export const ContentCtxProvider = ({ children }) => {
  const { options } = useContext(OptionsCtx)
  const { content: { tutorialCategories } } = options

  return (
    <ContentCtx.Provider value={{
      tutorialCategories
    }}>
      {children}
    </ContentCtx.Provider>
  )
}
