import React, { useContext, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import NavButton from '../components/NavButton'
import Header from '../components/Header'
import { UserContext } from '../UserContext'
import { BadgesCtx } from '../BadgesCtx'
import theme from '../constants/theme'
import { Certificate } from '../components/Icons'
import DashedLine from '../assets/DashedLine.svg'
import { hardcodedDataOnPage } from '../constants/assets/data'
import { useConfigContext } from '../hooks'

const textReveal = css`
  @keyframes reveal {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: reveal;
  animation-duration: 2s;
  animation-fill-mode: forwards;
`

const Wrapper = styled.section`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const ContentWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Gradient = styled.div`
  text-align: left;
  background-size: cover;
  display: flex;
  flex-flow: row;
  padding: 30px 30px 32px 30px;
  margin-top: -15px;
`

const HeadingWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  opacity: 0;

  ${textReveal}

  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: ${(c) => (c.theme.retailer.default ? '#012979' : '#221f20')};
    margin: 12px 0px 8px 0px;
  }

  .username {
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    font-size: 32px;
    line-height: 40px;
    color: ${(c) => (c.theme.retailer.default ? '#012979' : '#221f20')};
    margin: 0px;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;

  ${textReveal}

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: #313334;
    line-height: 24px;
    margin: 0;
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #313334;
    margin: 0;

    span {
      color: black;
      margin: 5px;
      font-weight: 700;
      display: block;
    }
  }
`

const DashedWrapper = styled.img`
  margin: 32px auto;
`

const BottomWrapper = styled.div`
  width: 60%;
  margin: 0px auto;
  color: #313334;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 32px;

  ${textReveal}

  p {
    display: block;
    font-size: 24px;
    margin: 0 auto;
    text-transform: lowercase;
    color: #221f20;
  }

  .name {
    display: block;
    width: 180px;
    font-size: 24px;
    margin: 0 auto 22px auto;

    @media (min-width: 375px) and (max-width: 767px) {
      margin-bottom: 6px;
    }
  }
`

const TutorialComplete = ({ onLinkClick }) => {
  const { userName } = useContext(UserContext)
  const { customerConfig: { customer } } = useConfigContext()
  const { currentCategory: badge, currentTask } = useContext(BadgesCtx)

  const { name } = badge
  const tutorialsComplete = badge?.wcTutorials?.filter((task) => task.isDone).length
  const tutorialsTotal = badge?.wcTutorials?.length || false
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customer].pagesData.tutorialComplete,
    [],
  )

  return (
    <Wrapper aria-label="There is new information on the screen">
      <Header inverted={true} pageName={name} />
      <ContentWrapper role='main' ref={(input) => input && input.focus()}>
        <Gradient>
          <HeadingWrapper>
            <h1>{pagesData.title}</h1>
            <span className="username">{userName}</span>
          </HeadingWrapper>
        </Gradient>
        <TextWrapper>
          <h2>{currentTask.description}</h2>
          <p>
            Status: <span>completed</span>
          </p>
        </TextWrapper>
        <Certificate
          fill={theme.retailer.default ? '#012979' : '#221f20'}
          clipColor={
            theme.retailer.default ? '#b92e1b' : theme.retailer.primaryColor
          }
          circleColor={theme.retailer.default ? '#012979' : '#221f20'}
        />
        <DashedWrapper src={DashedLine} alt='' aria-hidden/>
        <BottomWrapper>
          <span className="name">{name}</span>
          <p className="tutorialsCompleted">
            {String(tutorialsComplete)} <span> of {tutorialsTotal}</span>
          </p>
        </BottomWrapper>
      </ContentWrapper>
      <NavButton title="Continue learning" href={onLinkClick} />
    </Wrapper>
  )
}

export default TutorialComplete
