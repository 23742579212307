export const levels = [
  {
    createdTime: '2021-03-08T13:09:31.000Z',
    id: '6c1d2b3c-926c-4bc9-9e2d-200fcd5e5f7e',
    title: 'Initiate',
    points: 10,
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
  },
  {
    createdTime: '2021-03-01T11:59:19.000Z',
    id: '81d31b56-7c53-4a66-bf07-d0a0cea5e7c5',
    title: 'Pro',
    points: 60,
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
  },
  {
    createdTime: '2021-03-01T11:59:02.000Z',
    id: 'b3978dfe-cdbb-4671-8178-0cc5faeaede9',
    title: 'Rookie',
    points: 30,
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
  },
  {
    createdTime: '2021-03-01T11:59:10.000Z',
    id: 'b398da68-681f-4850-a57b-285e62045e92',
    title: 'Halfway',
    points: 45,
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
  },
  {
    createdTime: '2021-03-01T11:59:30.000Z',
    id: 'e2fcb175-eefe-4717-8845-a5631074acaf',
    title: 'Expert',
    points: 100,
    projectId: 'eb601f5a-0201-4d49-b543-2cea689204de',
  },
]
