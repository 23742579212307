export const paramApiConfig = {
  academyApiToken: process.env.REACT_APP_ACADEMY_API_TOKEN,
  resetProgressEnabled: process.env.REACT_APP_RESET_PROGRESS_ENABLED,
  gamifyApiUrl: process.env.REACT_APP_GAMIFY_API_URL,
  analyticsApiUrl: process.env.REACT_APP_ANALYTICS_API_URL,
  assetsUrl: process.env.REACT_APP_ASSETS_URL,
  kmsApiBase: process.env.REACT_APP_KMS_API_BASE,
  instanceEnvironment: process.env.REACT_APP_INSTANCE_ENVIRONMENT,
  iframeHost: process.env.REACT_APP_IFRAME_HOST,
}

const getNonEmptyParam = (config) => (requestedParam) => {
  if (config[requestedParam] !== undefined) {
    return config[requestedParam]
  }

  throw new Error(`${requestedParam} not set!`)
}

export const getParam = getNonEmptyParam(paramApiConfig)
