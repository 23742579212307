import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import PageSwitcher from '../components/PageSwitcher'
import Slider from '../components/Slider'
import { TimeSpentContext } from '../TimeSpentCtx'
import { hardcodedDataOnPage } from '../constants/assets/data'

import { useConfigContext } from '../hooks'
import { levels as levelsData } from '../constants/levels'

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  padding-bottom: 120px;
  margin: 0 auto;
  overflow-y: auto;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  color: #313334;
  font-size: 22px;
  margin: 6px;
  margin-top: 16px;
`

const Info = styled.h2`
  color: #373737;
  font-size: 14px;
  padding: 8px 65px 32px 65px;
  margin-bottom: 0;
  font-weight: 200;
`

function sortByPoints(a, b) {
  if (a.points < b.points) {
    return -1
  }
  if (a.points > b.points) {
    return 1
  }
  return 0
}

const LearningCurve = () => {
  const { timeSpent } = useContext(TimeSpentContext)
  const [levels, setLevels] = useState([])
  const { customerConfig: { customer }  } = useConfigContext()
  const [isFetching, setIsFetching] = useState(true)
  const fetchLevels = useCallback(async () => {
    const sortedLevels = levelsData.sort(sortByPoints)
    const rangedLevels = sortedLevels.map((level, index, arr) => {
      if (index === 0) {
        return {
          ...level,
          min: 0,
        }
      }

      return {
        ...level,
        min: arr[index - 1].points,
      }
    })
    const levelMap = pagesData.levels
    const mappedLevel = rangedLevels.map((level) => {
      const { badgeText, icon } = levelMap.find(
        (LEVEL) => LEVEL.title === level.title
      )
      return {
        ...level,
        icon,
        badgeText,
        placeholder: `${level.points} Minutes`,
      }
    })
    setLevels(mappedLevel.reverse())
    setIsFetching(false)
  }, [])
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customer].pagesData.learningCurve,
    []
  )

  useEffect(() => {
    const fetchingWithDuration = setTimeout(() => {
      fetchLevels()
    }, 500)

    return () => clearTimeout(fetchingWithDuration)
  }, [fetchLevels])

  const minutes = Math.ceil(timeSpent / 60)
  return (
    <Wrapper>
      <Header pageName={pagesData.title} inverted={true} />
      <PageSwitcher
        navLeft="/badges"
        navRight="/main-menu"
        pageTitle={`My ${pagesData.title}`}
        pageNumber={4}
      />
      <main>
        <Title>{pagesData.title}</Title>
        <Info>{pagesData.description}</Info>
        <Container>
          {!isFetching
          && levels.map((level, index) => (
            <Slider
              key={level.title}
              placeholder={level.placeholder}
              title={level.title}
              previousLevelTitle={levels[index + 1]?.title}
              min={level.min}
              max={level.points}
              badgeText={level.badgeText}
              icon={level.icon}
              value={minutes}
            />
          ))}
        </Container>
      </main>
    </Wrapper>
  )
}

export default LearningCurve
