import React from 'react'
import { Helmet } from 'react-helmet'

const MetadataManager = ({ title }) => {
  const defaultText = 'Welcome Center'

  const newTitle = (() => {
    if (title) {
      const endText = title.toLowerCase().includes(defaultText.toLowerCase())
        ? ''
        : ` | ${defaultText}`
      return title + endText
    }
    return defaultText
  })()

  return (
    <Helmet>
      <title>{newTitle}</title>
    </Helmet>
  )
}

export default MetadataManager
