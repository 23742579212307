import { v4 as uuidv4 } from 'uuid'
import { getParam } from '../helpers/parameters'
import debounce from '../lib/debounceAndAttachBrowserInfo'

type DefaultConf = {
  appId: string
  sessionId: string
  customer: string
  brand: string
  ip: string
}

type EventData = {
  app_instance_id: string
  session_id: number
  page_url: string
  user_agent: string
  brand: string
  customer: string
  app_instance_time: string
  page_title: string
}

export interface TupleArr {
  eventData: EventData
  event: string
}

type AnalyticsData = {
  app_instance_id: string
  app_instance_time: string
  page_url: string
  user_agent: string
  timestamp: string
  session_id: string
  permanent_id: string | null
  ip_address: string
  customer: string
  brand: string
  event_category: string
  event: string
  badge_id?: string,
  badge_name?: string,
  page_title?: string,
  time_spent?: number,
  user_gender?: string,
  device_model?: string,
}

const isClient = typeof window !== 'undefined'
const appStartTime = new Date()

const getAnalyticsApiUrl = async () => {
  const analyticsApiUrl = await getParam('analyticsApiUrl')
  return `${analyticsApiUrl}/analytics`
}

const initAnalyticsIdentifiers = (localStorage: Storage | false) => {
  if (!isClient || !localStorage) return
  let instId = localStorage.getItem('appInstanceId')
  let userId = localStorage.getItem('userId')
  let sessionId = sessionStorage.getItem('sessionId')

  if (!instId) {
    instId = uuidv4()
    localStorage.setItem('appInstanceId', instId)
  }
  if (!userId) {
    userId = uuidv4()
    localStorage.setItem('userId', userId)
  }
  if (!sessionId) {
    sessionId = uuidv4()
    sessionStorage.setItem('sessionId', sessionId)
  }
  return { instId, userId, sessionId }
}

export const ids = initAnalyticsIdentifiers(isClient && localStorage)

export const getDefaultBrowserConfig = ({
  appId,
  sessionId,
  brand,
  customer,
  ip,
}: DefaultConf) => ({
  app_instance_id: appId,
  app_instance_time: new Date().valueOf() - appStartTime.valueOf(),
  page_url: isClient && window.location.href,
  user_agent: isClient && navigator.userAgent,
  timestamp: (new Date()).toISOString(),
  session_id: sessionId,
  permanent_id: localStorage.getItem('permanent_id'),
  ...(localStorage.getItem('subscriber_id')
    && { subscriber_id: localStorage.getItem('subscriber_id') }),
  ip_address: ip,
  customer,
  brand,
})

const payloadToSend = async (analyticsData: AnalyticsData) => {
  const url = await getAnalyticsApiUrl()
  if (!analyticsData.permanent_id) {
    console.error(`An error occurred while sending analytics. The permanent_id is null. Check if ${process.env.REACT_APP_IFRAME_HOST} is working correctly`)
    return
  }
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(analyticsData),
  })
}

export const postAnalytics = debounce(payloadToSend, 5000)
