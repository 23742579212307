import { getDefaultBrowserConfig, ids } from '../services/analytics'
import { getUserIp } from '../services/getUserIp'

function debounce(fn, wait = 0, customer) {
  let deferred
  let timer
  let pendingArgs = []
  return async function debounced(...args) {
    const currentWait = wait
    const ip = await getUserIp()
    const browserConfig = getDefaultBrowserConfig({
      appId: ids.instId,
      sessionId: ids.sessionId,
      customer,
      brand: customer,
      ip,
    })

    const payloadToSend = {
      ...browserConfig, ...args[0].data,
      event: args[0].category,
    }

    return fn.call(this, payloadToSend)

    if (deferred) {
      clearTimeout(timer)
    } else {
      deferred = defer()
    }
    pendingArgs.push(payloadToSend)
    timer = setTimeout(reset.bind(this), currentWait)

    return deferred.promise
  }

  function reset() {
    const thisDeferred = deferred
    clearTimeout(timer)

    Promise.resolve(fn.call(this, pendingArgs)).then(
      thisDeferred.resolve,
      thisDeferred.reject
    )

    pendingArgs = []
    deferred = null
  }
}

function defer() {
  const deferred = {}
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  return deferred
}

export default debounce
