import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  background-color: ${({ color, theme }) => (theme.retailer.default ? color : theme.retailer.primaryColor)};
  border-radius: 50%;
  border: 3px solid #e8e8e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 190px;
  box-shadow: ${({ color, theme }) => (theme.retailer.default
    ? `0px -2px 62px 5px ${color}`
    : `0px -2px 62px 5px ${theme.retailer.primaryColor}`)};

  position: absolute;
  top: 105px;

  @media (min-width: 340px) {
    top: 108px;
  }

  ${({ animation }) => (animation
    ? css`
          animation-name: reduceMainCircle;
          animation-delay: 1.8s;
          animation-duration: 0.8s;

          animation-fill-mode: forwards;

          @keyframes reduceMainCircle {
            0% {
              width: 190px;
              height: 190px;
            }

            100% {
              width: 150px;
              height: 150px;
            }
          }
        `
    : css`
          @media (min-width: 340px) and (max-width: 475px) {
            width: 160px;
            height: 160px;
            top: 70px;
          }
        `)}

  div {
    ${({ animation }) => animation
      && css`
        animation-name: reduceCircle;
        animation-delay: 1.8s;
        animation-duration: 0.8s;

        animation-fill-mode: forwards;

        @keyframes reduceCircle {
          0% {
            width: 170px;
            height: 170px;
          }

          100% {
            width: 130px;
            height: 130px;
          }
        }
      `}
  }
`

const InnerCircle = styled(Wrapper)`
  width: 170px;
  height: 170px;
  box-shadow: none;

  position: static;

  @media (min-width: 340px) and (max-width: 475px) {
    width: 140px;
    height: 140px;
  }
`

const Icon = styled.img`
  max-width: 134px;
  max-height: 134px;
  padding: 5px;
  filter: ${({ theme }) => !theme.retailer.default && 'invert(1)'};

  @media (min-width: 340px) and (max-width: 475px) {
    height: 80px;
  }
`

const Achieve = ({
  icon, color, animation, ...props
}) => (
  <Wrapper animation={animation} color={color} {...props}>
    <InnerCircle>
      <Icon src={icon} alt="" aria-hidden />
    </InnerCircle>
  </Wrapper>
)

export default Achieve
