export const getAssetUrl = (url) => (fileName) => (`${url}/${fileName}`)

export const convertHTMLtoText = (html) => {
  if (!document) return html
  const el = document.createElement('div')
  el.innerHTML = html
  return el.textContent
}

export const getCustomer = () => {
  const isProd = window.location.hostname.includes('welcomecenter')
  const customer = (isProd ? window.location.hostname.split('.')[1]
    : window.location.hostname.split('-')[1]) || process.env.REACT_APP_CUSTOMER
  return customer
}

/**
 * Capitalizes first letters of words in string.
    ^ matches the beginning of the string.
    \w matches any word character.
    {1} takes only the first character.
    Thus, ^\w{1} matches the first letter of the word.
    | works like the boolean OR. It matches the expression after and before the |.
    \s+ matches any amount of whitespace between the words (E.g. spaces, tabs, or line breaks).
*/
export const capitalize = (words) => words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())

export const capitalizeFirstLetter = (word) => word.toString().charAt(0).toUpperCase() + word.slice(word.length - (word.length - 1))

/**
 * Transforms a string into title case, where the first letter of every word is capitalized except for certain prepositions,
 * articles and conjunctions.
 * @param {String} str String.
 * @param {Array} array Optional array to ignore strings of your choice, such as acronyms.
 * @return {String} String converted to Title Case.
*/
export const titleCase = (str, array = []) => {
  const ignoredWords = ['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'from', 'if', 'in', 'nor', 'on', 'of', 'off', 'or', 'out', 'over', 'the', 'to', 'your', 'vs']

  const words = str.split(' ')
  const formattedWords = words.map((word) => {
    if (array.includes(word)) return word
    if (!ignoredWords.includes(word)) return capitalizeFirstLetter(word)
    return word.toLowerCase()
  })

  return formattedWords.join(' ')
}

export const pressHotkeys = ({ keyCode, target }) => {
  if (keyCode === 32 || keyCode === 13) target.click()
}
