import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import NavButton from '../components/NavButton'
import Header from '../components/Header'
import BreadCrumbs from '../components/BreadCrumbs'
import Shielded from '../assets/Shielded.png'
import { useConfigContext, useLocalStorageState } from '../hooks'
import PageSwitcher from '../components/PageSwitcher'
import { Way } from '../components/Icons'
import theme from '../constants/theme'
import { hardcodedDataOnPage } from '../constants/assets/data'
import { BadgesCtx } from '../BadgesCtx'
import BadgeAward from '../components/BadgeAward'

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;

  @media (max-width: 720px) {
    padding-bottom: 137px;
  }
`

const Info = styled.p`
  color: #221f20;
  font-size: 14px;
  margin-top: 16px;
  width: 80%;
  line-height: 20px;
  @media (min-width: 270px) and (max-width: 450px) {
    width: 100%;
    padding: 5px;
  }
`

const Path = styled.div`
  margin: 25px auto 50px auto;
  justify-content: center;
  display: flex;
  color: #313334;
  @media (max-width: 497px) {
    margin-bottom: 30px;
  }

  svg {
    margin-left: -82px;
    margin-top: -2px;
    float: left;
    position: relative;

    @media (min-width: 270px) and (max-width: 480px) {
      margin-top: 4px;
      margin-left: 6px;
    }
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const TextWrapper = styled.div`
  text-align: start;
  margin: 0 0 5px 0;

  h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    padding: 5px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #767676;
    max-width: 350px;
    padding: 5px;
    margin-top: 0px;
  }
  @media (min-width: 375px) and (max-width: 465px) {
    h2 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
`

const Gradient = styled.div`
  text-align: left;
  display: flex;
  flex-flow: row;
  margin: 50px 0px;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 500px) {
    margin: 30px 0px 15px 10px;
  }
`
const TopTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: 40px;

  h1 {
    font-size: 22px;
    color: #221f20;
    margin: 10px;
    font-weight: 700;
    margin: 0;
  }
`
const Icon = styled.img`
  width: 50px;
  height: 92px;
`

const Protect = () => {
  const [protectVisited, setProtectVisited] = useLocalStorageState('protectVisited')
  const [extraBadgesProgress, setExtraBadgesProgress] =
    useLocalStorageState('extraBadges')
  const height = document.body.offsetHeight
  const { customerConfig } = useConfigContext()
  const badgeCtx = useContext(BadgesCtx)
  const badge = extraBadgesProgress.find((i) => i.link === '/protect')
  const [isShowAward, setIsShowAward] = useState(false)
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.protect,
    []
  )

  const handleClickProtect = () => {
    if (protectVisited) return
    setIsShowAward(true)
    badgeCtx.completeExtraBadge(badge.id)
    setProtectVisited(true)
  }

  useEffect(() => {
    const el = document.querySelector('.paragraph')
    if (!el) return
    if (height < 700) {
      el.addEventListener('focus', () => {
        el.scrollIntoView({ block: 'center', inline: 'center' })
      })
    }
  }, [height])

    const historyPages = useMemo(
      () => [
        { title: 'Main Menu', link: '/main-menu' },
        {
          title: pagesData.title,
        },
      ],
      [badgeCtx]
    )

  if (isShowAward)
    return (
      <BadgeAward
        name={badge.name}
        onDismiss={() => setIsShowAward(false)}
        badgeId={badge.id}
        icon={badge.icon_name}
      />
    )

  return (
    <Wrapper aria-label="There is new information on the screen">
      <Header inverted={true} pageName={pagesData.title}/>
      <PageSwitcher
        navLeft="/learning-curve"
        navRight="/explore-more"
        pageTitle="My badges"
        pageNumber={1}
      />
      <BreadCrumbs historyPages={historyPages} />
      <Gradient>
        <Icon src={Shielded} alt="" aria-hidden />
        <TopTextWrapper>
          <h1>{pagesData.title}</h1>
          <Info>{pagesData.description}</Info>
        </TopTextWrapper>
      </Gradient>
      <Path id="main-content">
        <Way
          color={
            theme.retailer.default ? '#C74100' : theme.retailer.primaryColor
          }
        />
        <Flex>
          {pagesData.steps.map((i, index) => (
          <TextWrapper key={`${i.title}-${index}`}>
            <h2 aria-label={i.title}>
              {i.title}
            </h2>
            <p>{i.description}</p>
          </TextWrapper>
        ))}
        </Flex>
      </Path>
        <NavButton
          href={customerConfig.protectPhoneLink}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex="0"
          className="center"
          title="Protect my phone"
          aria-label={`Protect My Phone in ${customerConfig.customerModalName} opens in new window`}
          onClick={handleClickProtect}
        />
    </Wrapper>
  )
}

export default Protect
