import React, { useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { COLOR } from '../constants/color'
import { pressHotkeys } from '../helpers/utils'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f9f9f9;
  align-items: center;
  width: 100%;
  height: 70px;

  @media (min-width: 360px) and (max-width: 767px) {
    height: 56px;
    top: 80px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0px 12px;
  width: 80%;

  .arrow1 {
    margin-right: 32px;
  }

  .arrow2 {
    margin-left: 32px;
  }

  h1 {
    font-size: 20px;
    color: #012979;
    text-transform: uppercase;
    margin: 6px;
  }
`

const WrapperMenuArrow = styled.div`
  width: 780px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 558px) and (max-width: 767px) {
    width: 450px;
  }
`

const NavLinkItem = styled.div`
  width: 25px;
  height: 25px;
  background: ${({ theme }) =>
    theme.retailer.default ? '#fff' : theme.retailer.primaryColor};
  border: none;
  border-radius: 14px;
  transform: ${({ inverted }) =>
    inverted ? 'rotate(180deg)' : 'rotate(0deg)'};
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &:after {
    content: '';
    height: 8px;
    width: 8px;
    right: 9px;
    position: absolute;
    border-top: #fff solid 2px;
    border-left: #fff solid 2px;
    transform: rotate(135deg);

    ${({ theme }) =>
      theme.retailer.default &&
      css`
        border-top: ${theme.retailer.primaryColor} solid 2px;
        border-left: ${theme.retailer.primaryColor} solid 2px;
      `}
  }
`

const DotWrapper = styled.div`
  display: flex;
  flex-flow: row;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 90%;
    overflow: hidden;
  }
`

const Dot = styled(NavLink)`
  padding: 9px 15px;
  border-radius: 30px;
  background: ${({ $active, theme }) =>
    $active ? theme.retailer.primaryColor : 'none'};
  margin-right: 48px;

  font-weight: 700;

  font-size: 16px;

  transition: 0.2s all ease;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ $active, theme }) =>
    theme.retailer.default && $active ? '#fff' : '#221f20'};

  :last-child {
    margin-right: 0;
  }

  :hover {
    background-color: ${({ $active }) => !$active && '#e8e8e9'};
  }

  :focus, :focus-visible {
    outline-offset: 6px;
    outline-color: black;
  }

  @media (max-width: 767px) {
    margin: 0px;
    white-space: nowrap;
    display: ${({ $active }) => ($active ? 'block' : 'none')};
    font-size: 14px;
    background: none;
    border-radius: 0;
    border-bottom: ${({ $active, theme }) =>
      $active
        ? `3px solid ${theme.retailer.primaryColor}`
        : `1px solid ${COLOR.grayLight}`};

    color: ${({ $active }) => ($active ? '#221f20' : '#868686')};
  }
`

const Header = ({ wrpStyle, children, navLeft, navRight, pageNumber }) => {
  const mainMenuTabRef = useRef()
  const learningCurveRef = useRef()

  const handleKeyPress = useCallback(({ key }) => {
    if (key === 'Home') {
      mainMenuTabRef.current.focus()
    }
    if (key === 'End') {
      learningCurveRef.current.focus() 
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  return (
    <nav>
      <Wrapper style={wrpStyle} className="page-switcher">
        <ButtonWrapper>
          <WrapperMenuArrow className="menu-arrows">
            <NavLink
              aria-label={'Previous Page'}
              role="button"
              to={navLeft}
              className="arrows arrow1"
              onKeyDown={pressHotkeys}
            >
              <NavLinkItem inverted={true} />
            </NavLink>
            <DotWrapper className="dots">
              <Dot
                aria-label="Go To Main Menu"
                $active={pageNumber === 1}
                to="/main-menu"
                ref={mainMenuTabRef}
                onKeyDown={pressHotkeys}
              >
                Main Menu
              </Dot>
              <Dot
                aria-label="Go To Explore More"
                $active={pageNumber === 2}
                to="/explore-more"
                onKeyDown={pressHotkeys}
              >
                Explore More
              </Dot>
              <Dot
                aria-label="Go To My Badges"
                $active={pageNumber === 3}
                to="/badges"
                onKeyDown={pressHotkeys}
              >
                My Badges
              </Dot>
              <Dot
                aria-label="Go To Learning Curve"
                $active={pageNumber === 4}
                to="/learning-curve"
                ref={learningCurveRef}
                onKeyDown={pressHotkeys}
              >
                Learning Curve
              </Dot>
            </DotWrapper>
            <NavLink
              aria-label="Next Page"
              role="button"
              to={navRight}
              className="arrows arrow2"
              onKeyDown={pressHotkeys}
            >
              <NavLinkItem />
            </NavLink>
          </WrapperMenuArrow>
        </ButtonWrapper>
        {children}
      </Wrapper>
    </nav>
  )
}

export default Header
