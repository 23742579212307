import React, {
  useContext, useState, useRef,
} from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useLocalStorageState } from '../hooks'
import { UserContext } from '../UserContext'
import { fetchCategories, fetchDeviceBySerial } from '../services'
import { serials } from '../constants/productSerialNumbers'

const ModalWrapper = styled.div`
  position: absolute;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 0 auto;
  height: 95.5%;

  z-index: 1;

  background-color: #9595958f;

  transition: 0.3s all ease;
  opacity: ${({ isHidden }) => (isHidden ? '1' : '0')};
`

const ModalBody = styled.div`
  position: relative;

  background-color: white;

  border-radius: 13px;

  padding: 30px;
  margin: 0 15px;

  max-width: 600px;

  overflow: hidden;
`

const ModalTitle = styled.h1`
  font-size: 26px;

  border-bottom: 1px black solid;

  color: black;

  margin: 0;
  padding-bottom: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Button = styled.button`
  border: 1px solid #0000008c;
  border-radius: 3px;
  background-color: ${({ color, isDisabled = false }) => {
    if (isDisabled) return 'gray'
    if (color) return color
    return '#0530ff63'}};
  cursor: ${({ isDisabled = false }) => (isDisabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  margin: 10px 30px;
  padding: 5px 25px;
  transition: 0.2s all ease;
  :hover {
    box-shadow: ${({ isDisabled = false }) => (isDisabled ? '0px' : ' 1px 1px 7px 0px #38383c')};
  }

  @media (min-width: 300px) and (max-width: 600px) {
    padding: 5px 10px;
    margin: 10px;
  }
`

const CloseButton = styled(Button)`
  background-color: #60616663;
`

const OnboardingButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ToggleOnboardingButton = styled(Button)`
  margin: 10px;
`

const ManualDeviceInput = styled.input`
  font-size: 14px;
  width: 40%;
  border: 1px solid black;
  border-radius: 7px;
  background-color: ${({ isActive }) => (isActive ? '#0530ff63' : 'transparent')};
`

const Title = styled.p`
  margin-bottom: 5px;
`

const Error = styled.p`
  margin-bottom: 5px;
  margin-top: 0px;
  color: red;
  height: 16px;
`

const InputContainer = styled.div`
  padding: 8px;
  margin: 5px;
  display: flex;
  justify-content: center;
  gap: 7px;
  button {
    margin: 0px;
  }
`

const SerialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 325px;
  justify-content: space-between;
  margin-bottom: 50px;
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

const DeviceListModal = ({ isDevicesModal, setIsDevicesModal }) => {
  const [deviceInfo, setDeviceInfo] = useLocalStorageState('device', {})
  const [showOnboarding, setShowOnboarding] = useLocalStorageState('showOnboarding')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const referenceIdRef = useRef(null)
  const { setUser } = useContext(UserContext)

  const history = useHistory()

  const nextStep = async () => {
    history.replace('/main-menu')
    setUser({
      name: 'Test User',
      gender: 'unknown',
    })
  }

  const handleClickApply = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const response = await fetchCategories({ referenceId: referenceIdRef.current.value })
      if (response.length === 0) {
        setErrorMessage('This device has no categories for testing')
        setTimeout(() => {
          setErrorMessage('')
        }, 4000)
      }
    } catch (error) {
      console.error(error)
    }
    setDeviceInfo({
      referenceId: referenceIdRef.current.value,
      displayName: 'Testing environment',
    })
    setIsLoading(false)
  }

  const handleClickSerial = async (serial) => {
    const product = await fetchDeviceBySerial(serial)
    if (!product) {
      setErrorMessage('This device has no categories for testing')
        setTimeout(() => {
          setErrorMessage('')
        }, 4000)
      return
    }
    const referenceId = product.reference_id
    if (!referenceId) return
    referenceIdRef.current.value = referenceId
  }

  return (
    <ModalWrapper isHidden={isDevicesModal}>
      <ModalBody>
        <ModalTitle>{`Selected Device: ${deviceInfo.referenceId || ''}`}</ModalTitle>
        <div>
          <Title>Enter the device's Customer ID</Title>
          <InputContainer>
              <ManualDeviceInput ref={referenceIdRef} />
              <Button color={'#c7e64e'} isDisabled={isLoading} onClick={handleClickApply}>Apply</Button>
          </InputContainer>
          <Error>{errorMessage}</Error>
        </div>
        <p>Or select the serial number of the product</p>
        <FlexCenter>
          <SerialsContainer>
          {serials.map((serial) => 
            <Button key={serial} onClick={() => handleClickSerial(serial)}>
              {serial}
            </Button>)}
          </SerialsContainer>
        </FlexCenter>
        <ButtonContainer>
          <CloseButton onClick={() => setIsDevicesModal(false)}>
            Back
          </CloseButton>
          <OnboardingButtonContainer>
            <ToggleOnboardingButton
              onClick={() => setShowOnboarding(!showOnboarding)}
            >
              Onboarding - {showOnboarding ? 'on' : 'off'}
            </ToggleOnboardingButton>
            <Button onClick={nextStep}>Next</Button>
          </OnboardingButtonContainer>
        </ButtonContainer>
      </ModalBody>
    </ModalWrapper>
  )
}

export default DeviceListModal
