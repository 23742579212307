import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import Header from '../components/Header'
import Greetings from '../components/Greetings'
import Man from '../assets/Man.svg'
import Woman from '../assets/Woman.svg'
import WelcomeBadge from '../assets/WelcomeBadge.svg'
import { UserContext } from '../UserContext'
import BackgroundCircle from '../assets/BackgroundCircle.svg'
import ChatBox from '../components/ChatBox'
import NavButton from '../components/NavButton'
import { useConfigContext } from '../hooks'
import Achieve from '../components/Achieve'
import { hardcodedDataOnPage } from '../constants/assets/data'

const Wrapper = styled.div`
  min-height: 100%;
  background: ${({ theme }) =>
        `${
          theme.retailer.default
            ? 'linear-gradient(0deg, #012979, #012979)'
            : theme.retailer.backgroundGradient
        }`}
      no-repeat,
    url(/images/Home.png);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply, normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  overflow: hidden;
  main {
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const BadgeIntroductionWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  gap: 10px;
  padding: 5px;
  position: relative;
  flex-direction: column;
  bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 200px) and (max-width: 760px) {
    bottom: 0;
    gap: 10px
  }
`

const UserWrapper = styled.div`
  display: none;
  margin: 0 auto;

  animation-name: hideUser;
  animation-delay: 1.8s;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;

  z-index: 1;

  @keyframes hideUser {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  p {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    margin: 3px;
  }

  @media (min-width: 360px) {
    margin: 0 auto 330px auto;
    @keyframes hideUser {
      100% {
        opacity: 0;
        margin: 0px;
      }
    }
  }
`

const UserIcon = styled.img`
  border: 3px solid #fff;
  border-radius: 50px;
  background-color: #fff;
`

const UserChatWrapper = styled.div`
  display: flex;
  top: -58%;
  left: 5%;
  align-items: center;

  @media (min-width: 200px) and (max-width: 760px) {
    top: -10%;
    left: 4.2%;
  }

  @media (min-width: 760px) and (max-width: 1200px) {
    top: -58%;
    left: 5%;
  }
`

const SmallUserIcon = styled.img`
  border: 3px solid #fff;
  border-radius: 50px;

  background-color: #fff;

  margin-right: 30px;

  width: 80px;
  height: 80px;

  @media (min-width: 200px) and (max-width: 760px) {
    width: 40px;
    height: 40px;
  }

  ${(props) =>
    props.theme.media.iphone7(css`
      bottom: 55%;
    `)}
`

const Chatty = styled.div`
  background: url(${({ iconLink }) => iconLink}) center no-repeat;

  width: 73px;
  height: 73px;
  position: absolute;
  top: 75%;
  left: 85%;

  border-radius: 50%;

  background-size: 73px;

  @media (min-width: 200px) and (max-width: 760px) {
    width: 65px;
    height: 65px;
    background-size: 65px;

    top: 58%;
    left: 80%;
  }
`

const BackgroundRing = styled.div`
  ${(props) =>
    props.effect
      ? css`
          ${setTransitionEffects(props.effect)}
        `
      : ''}
  background: url(${BackgroundCircle});
  height: 370px;
  width: 440px;
  background-repeat: no-repeat;
  background-position: center;
  top: ${(props) => (props.animate ? '-200px' : '0px')};
  transition-delay: ${(props) =>
    props.transitionDelay ? `${props.transitionDelay}s` : '0s'};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: reduceBackgroundCircle;
  animation-delay: 1.8s;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;

  @keyframes reduceBackgroundCircle {
    100% {
      height: 347px;
      background-size: 340px;
      width: 260px;
    }
  }

  @media (min-width: 200px) and (max-width: 760px) {
    top: ${(props) => (props.animate ? '-220px' : '-50px')};
    background-size: 400px;
    height: 360px;
    width: 334px;
  }
`

const WelcomeContainer = styled.div`
  position: absolute;
  font-weight: bold;
  bottom: 0px;
  @media (min-width: 200px) and (max-width: 760px) {
    bottom: -100px;
  }
`

const AnimationWrapper = styled.div`
  place-self: ${({ placeSelf }) => placeSelf || ''};
  ${(props) =>
    props.effect
      ? css`
          ${setTransitionEffects(props.effect)}
        `
      : ''}
  transition-delay: ${(props) =>
    props.transitionDelay ? `${props.transitionDelay}s` : '0s'};
  opacity: ${(props) => (props.animate ? 1 : 0)};
`

const UserChatBox = styled(ChatBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: unset;
  z-index: 1;

  h1 {
    font-size: 20px;
    font-weight: normal;
  }

  @media (min-width: 200px) and (max-width: 760px) {
    p {
      font-size: 14px;
    }
  }
`

const ChattyChatBox = styled(ChatBox)`
  right: 10%;
  text-align: justify;
  bottom: 30%;

  min-width: 340px;
  padding: 32px;

  p {
    font-size: 20px;
    box-shadow: none !important;
  }

  span {
    font-weight: 700;
    color: #221f20;
  }

  @media (min-width: 200px) and (max-width: 760px) {
    padding: 10px;
    bottom: 33%;
    min-width: 274px;

    p {
      font-size: 14px;
    }
  }
`

const setTransitionEffects = (effect) => `
  -webkit-transition: ${effect};
  -moz-transition: ${effect};
  -ms-transition: ${effect};
  -o-transition: ${effect};
  `

const Welcome = () => {
  const [animate, setAnimate] = useState(false)
  const { userName, userGender } = useContext(UserContext)
  const { customerConfig } = useConfigContext()
  const badgeExplanationRef = useRef()
  const welcomeContainer = useRef()
  const history = useHistory()
  const avgFirstReadingFocusTime = 4500 // Approximate amount of time (in milliseconds) that some screen readers take to read the first text to focus on the next section
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.welcome,
    []
  )

  useEffect(() => {
    if (welcomeContainer && welcomeContainer.current) {
      welcomeContainer.current.focus()
    }
    const timeoutId = setTimeout(() => {
      const greetings = document.querySelector('.greetings')
      if (!greetings) return
      greetings.classList.add('animate__animated', 'animate__fadeOut')
      greetings.classList.remove('animate__fadeIn', 'animate__delay-1s')
      setAnimate(true)
    }, 800)

    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (welcomeContainer?.current) welcomeContainer.current.remove()
      if (badgeExplanationRef?.current) badgeExplanationRef.current.focus()
    }, avgFirstReadingFocusTime)

    return () => clearTimeout(timeoutId)
  }, [])
  return (
    <Wrapper>
      <Header
        inverted={true}
        notShow={true}
        wrpStyle={{ background: 'transparent' }}
        pageName={'Welcome'}
      />
      <main>
        <UserWrapper>
          <UserIcon
            src={userGender === 'male' ? Man : Woman}
            height={74}
            width={74}
            alt=''
          />
          <p aria-hidden={animate ? 'true' : 'false'}>Hi, {userName}</p>
        </UserWrapper>
        <BackgroundRing
          effect='all 0.7s ease-out'
          transitionDelay={1}
          animate={animate}
          aria-hidden='true'
        >
          <Achieve
            aria-hidden='true'
            animation={true}
            color={'#57df57'}
            icon={WelcomeBadge}
          />
        </BackgroundRing>
        <WelcomeContainer
          ref={welcomeContainer}
          className='animate__animated animate__fadeOut animate__delay-2s'
          aria-label={`Hi, ${userName}. Welcome to the family!`}
        >
          <Greetings
            aria-hidden
            className='greetings animate__animated animate__fadeIn animate__delay-1s'
            heading='Welcome to'
            text='The family!'
            style={{ width: '285px', margin: '0 auto' }}
            headingStyle={{ fontSize: 32 }}
            textStyle={{ fontSize: 32 }}
            inverted={animate}
          />
        </WelcomeContainer>
        <BadgeIntroductionWrapper ref={badgeExplanationRef}>
          <AnimationWrapper
            effect='opacity 0.7s ease-in-out'
            transitionDelay={1}
            animate={animate}
            placeSelf='flex-start'
          >
            <UserChatWrapper>
              <SmallUserIcon src={userGender === 'male' ? Man : Woman} alt='' />
              <UserChatBox>
                <div>
                  <h1
                    dangerouslySetInnerHTML={{ __html: pagesData.userPhrase }}
                  ></h1>
                </div>
              </UserChatBox>
            </UserChatWrapper>
          </AnimationWrapper>
          <AnimationWrapper
            transitionDelay={2}
            animate={animate}
            placeSelf='flex-end'
          >
            <ChattyChatBox>
              <div className='animate__animated animate__fadeIn animate__delay-3s'>
                <p
                  dangerouslySetInnerHTML={{ __html: pagesData.botPhrase_1 }}
                />
              </div>

              <div className='animate__animated animate__fadeIn animate__delay-4s'>
                <p
                  dangerouslySetInnerHTML={{ __html: pagesData.botPhrase_2 }}
                />
              </div>

              <div className='animate__animated animate__fadeIn animate__delay-5s'>
                <p
                  dangerouslySetInnerHTML={{ __html: pagesData.botPhrase_3 }}
                />
              </div>
            </ChattyChatBox>
          </AnimationWrapper>
        </BadgeIntroductionWrapper>
      </main>
      <div>
        <NavButton
          aria-label="Let's go!"
          className='animate__animated animate__fadeIn animate__delay-5s'
          onClick={() => {
            history.replace('/chatty-introduction')
          }}
          onKeyDown={(e) =>
            e.key === 'Enter' && history.replace('/chatty-introduction')
          }
          title="Let's go!"
        />
      </div>
    </Wrapper>
  )
}

export default Welcome
