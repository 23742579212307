export const getUserIp = async () => {
  try {
    const res = await fetch('https://api.ipify.org?&callback=getIP')
    const ip = await res.text()
    return ip
  } catch (error) {
    console.error(error)
    return ''
  }
}
