import ST from '../assets/ST_LogoMaster 1.png'
import TracFone from '../assets/WhiteLogo.svg'
import customersObj from '../config'
import { useConfigContext } from '../hooks'

const isIphone = window.navigator.userAgent
  .toLocaleLowerCase()
  .includes('iphone')

const iphoneMediaQueryMatch = window.matchMedia(`only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)`).matches

const retailerThemePayload = (retailer) => {
  switch (retailer) {
    case 'tracfone':
      return {
        default: true,
        lightTheme: true,
        primaryColor: '#012979',
        successColor: '#38D430',
        lightBadge: false,
        header: '#012979',
        secondaryColor: '#C74100',
        icon: { src: `${TracFone}`, color: '#012979' },
        backgroundGradientSlider:
          'linear-gradient(90deg, #57DF57 48.55%, rgba(87, 223, 87, 0) 97.1%);',
        backgroundGradient:
          'linear-gradient(180deg, #012979 54.92%, #57DF57 100%)',
      }
    case 'straighttalk':
      return {
        lightTheme: false,
        primaryColor: '#c7e64e',
        successColor: '#c7e64e',
        lightBadge: false,
        header: '#c7e64e',
        secondaryColor: '#c7e64e',
        icon: { src: `${ST}`, color: '#000' },
        backgroundGradientSlider:
          'linear-gradient(270deg, #FFFFFF 0%, #C7E64E 100%)',
        backgroundGradient:
          'linear-gradient(180deg, rgba(34, 31, 32, 0.7) 44.09%, #C7E64E 100%)',
      }
    default:
      return {
        default: true,
        lightTheme: true,
        primaryColor: '#012979',
        successColor: '#38D430',
        lightBadge: false,
        header: '#012979',
        secondaryColor: '#C74100',
        icon: { src: `${TracFone}`, color: '#012979' },
        backgroundGradientSlider:
          'linear-gradient(90deg, #57DF57 48.55%, rgba(87, 223, 87, 0) 97.1%);',
        backgroundGradient:
          'linear-gradient(180deg, #012979 54.92%, #57DF57 100%)',
      }
  }
}

export default (() => {
   const getCustomer = () => {
    const isProd = window.location.hostname.includes('welcomecenter')
    const customer = (isProd ? window.location.hostname.split('.')[1]
      : window.location.hostname.split('-')[1]) || process.env.REACT_APP_CUSTOMER
    return customer
  }
  const customer = getCustomer()

  return ({
    media: {
      iphone7: (styles) => (iphoneMediaQueryMatch && isIphone
        ? `
      @media only screen
        and (min-device-width: 375px)
        and (max-device-width: 667px)
        and (-webkit-min-device-pixel-ratio: 2) {
          ${styles}
      }`
        : ''),
    },
    retailer: retailerThemePayload(customersObj[customer].customer),
    color: {
      grayMid: '#717171',
      grayText: '#373737',
    },
  })
})()
