import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useAsync } from 'react-async'

import Button from './Button'
import { COLOR } from '../constants/color'
import { UserContext } from '../UserContext'
import { getParam } from '../helpers/parameters'

const fetchProgressEnabled = async () => {
  const value = await getParam('resetProgressEnabled')
  return value === '1'
}

const ResetProgress = () => {
  const history = useHistory()
  const { clearUser } = useContext(UserContext)
  const { data: enabled } = useAsync(fetchProgressEnabled)

  const handleClick = () => {
    const confirmed = window.confirm('Are you sure?')
    if (!confirmed) { return }

    clearUser()
    history.push('/registration')
  }

  if (!enabled) { return null }

  return (
    <Button
      title="Reset Progress"
      onClick={handleClick}
      btnStyle={{
        background: COLOR.red,
        marginTop: 20,
      }}
    />
  )
}

export default ResetProgress
