import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import DOMPurify from 'dompurify'

const createMarkup = (html) => ({ __html: html })

const renderContent = (text) => (
    <div dangerouslySetInnerHTML={createMarkup(DOMPurify.sanitize(text))} />
)

const InfoBox = ({
  node: { attribs: { title } }, tooltipOptions, height, icon,
}) => (
    <span>
      <ReactTooltip id={`tooltip-icon-${title}`} getContent={() => renderContent(title)} {...tooltipOptions} />
    </span>
)

InfoBox.propTypes = {
  node: PropTypes.shape({
    attribs: PropTypes.shape({
      class: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
  }).isRequired,
  tooltipOptions: PropTypes.shape({
    textColor: PropTypes.string,
    border: PropTypes.bool,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    event: PropTypes.string,
    multiline: PropTypes.bool,
  }),
  height: PropTypes.number,
  icon: PropTypes.string.isRequired,
}

export default InfoBox
