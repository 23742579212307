import React, {
  createContext, useState, useContext, useEffect,
} from 'react'
import { useAsync } from 'react-async'

import {
  fetchCategories,
} from './services'
import { DeviceCtx } from './DeviceCtx'
import { useLocalStorageState } from './hooks'
import { extraBadges } from './constants/extraBadges'
import { captureException } from '@sentry/react'

export const BadgesCtx = createContext({
  currentCategory: undefined,
  selectBadge: undefined,
  setCurrentTaskId: () => {},
  currentTask: {},
})

export const BadgesCtxProvider = ({ children }) => {
  const { referenceId } = useContext(DeviceCtx)
  const {
    data: categories = [],
    error,
    reload: reloadFetchCategories,
  } = useAsync(fetchCategories, {
    referenceId,
  })
  const [currentCategoryId, setCurrentCategoryId] = useLocalStorageState('currentCategoryId')
  const [currentTaskId, setCurrentTaskId] = useLocalStorageState('currentTaskId')
  const [progress, setProgress] = useLocalStorageState('progress')
  const [extraBadgesProgress, setExtraBadgesProgress] = useLocalStorageState('extraBadges')

  const getCategoryById = (categoryId) => {
    if (!progress) return null
    return progress.find((category) => category.id === categoryId)
  }

  const currentCategory = getCategoryById(currentCategoryId) || {}

  const getTask = (taskId) => {
    const tasks = currentCategory?.wcTutorials || []
    return tasks.find((task) => task.id === taskId) || {}
  }

  const updateProgress = (categoryId) => {
    const newProgress = progress.map(((badge) => {
      if (badge.id !== categoryId) return badge
      const completedTasks = badge.wcTutorials.filter((i) => i.isDone).length + 1
      const p = Math.round(completedTasks / badge.wcTutorials.length * 100)
      return {
        ...badge,
        isDone: p === 100,
        progress: p,
        wcTutorials: badge.wcTutorials.map((task) => {
          if (task.id !== currentTask.id) return task
          return {
            ...task,
            isDone: true,
          }
        }),
      }
    }))
    setProgress(newProgress)
  }

  const completeExtraBadge = (badgeId) => {
    setExtraBadgesProgress(extraBadgesProgress.map((item) => {
      if (item.id === badgeId) {
        return ({
          ...item,
          isDone: true,
          tasks: item.tasks ? item.tasks.map((task) => ({
            ...task,
            isDone: true,
          })) : undefined
        })
      }
      return item
    }))
  }

  const completeExtraBadgeTask = ({ taskTitle, badgeId }) => {
    setExtraBadgesProgress(extraBadgesProgress.map(badge => badge.id !== badgeId ? badge : 
      ({
        ...badge,
        tasks: badge.tasks.map((task) => task.title !== taskTitle ? task : 
        ({
          ...task,
          isDone: true
        }))
      })
      ))
  }

  const currentTask = getTask(currentTaskId)

  useEffect(() => {
    if (!progress) setProgress([])
    if (!extraBadgesProgress) {
      setExtraBadgesProgress(extraBadges)
    }
  }, [])

  useEffect(() => {
    reloadFetchCategories()
  }, [referenceId])

  useEffect(() => {
    if (error) {
      captureException(error)
    }
    if (!progress || error || categories.length === 0) return
    const tutorialsProgress = categories.map((i) => {
      const oldCategory = (progress.find((item) => item.id === i.id))
      if (oldCategory) {
        return ({
          ...oldCategory,
          wcTutorials: i.wcTutorials.map((item) => {
            const oldTutorial = oldCategory.wcTutorials.find((tutorial) => tutorial.id === item.id)
            if (oldTutorial) return oldTutorial
            return ({
              ...item,
              isDone: false,
            })
          }),
        })
      }
      return ({
        ...i,
        isDone: false,
        progress: 0,
        wcTutorials: i.wcTutorials,
      })
    })
    const newProgress = [...tutorialsProgress]
    if (extraBadgesProgress) {
      newProgress.push(...extraBadgesProgress)
    }
    setProgress(newProgress)
  }, [categories, extraBadgesProgress])

  return (
    <BadgesCtx.Provider value={{
      setCurrentCategoryId,
      currentCategory,
      setCurrentTaskId,
      currentTask,
      getCategoryById,
      categories,
      updateProgress,
      completeExtraBadge,
      completeExtraBadgeTask,
    }}>
      {children}
    </BadgesCtx.Provider>
  )
}
