import React, { createContext } from 'react'
import { appConfig } from './constants/assets/options.json'

export const OptionsCtx = createContext({
  options: {}
})

export const OptionsCtxProvider = (({ children }) => {

  return (
    <OptionsCtx.Provider value={{ options: appConfig }}>
      {children}
    </OptionsCtx.Provider>
  )
})

