export const addApiKey = (config = {}) => {
  const apiKey = process.env.REACT_APP_API_KEY

  return {
    ...config,
    headers: {
      ...config.headers,
      'x-api-key': apiKey,
    },
  }
}
